.select-drop {
  ::-webkit-scrollbar {
    // display: block;
    position: absolute;
    width: 10px !important;
    height: 5px !important;
  }

  position: relative;
  background-color: #fff;
  margin: 0px 8px 8px 8px;

  select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.9rem + 2px);
    padding: 0.45rem 1.9rem 0.45rem 0.9rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px;

    &:focus {
      border-color: #b1bbc4;
      outline: 0;
    }

    option {
      font-size: 16px;
    }
  }

  .select-border-redius {
    border-radius: 6px !important;
  }
}

// custom dropdown
.custom-select {
  position: relative;
  width: 185px;
  height: calc(1.5em + 0.9rem + 2px);
  margin-right: 15px;
}

.select-header {
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selected-header-value {
  width: 120px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

#arrow {
  border: solid #495057;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2.5px;
  transform: rotate(45deg);
  transition: transform 0.3s;
}

#arrow.open {
  transform: rotate(-135deg);
}

.select-options-ul {
  position: absolute;
  width: fit-content;
  min-width: 185px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: auto;
  border: 1px solid #ced4da;
  border-radius: 6px;
  background-color: #fff;
  margin-top: 5px;
  z-index: 100;
  margin-left: 0 !important;
}

.select-option-li {
  display: block;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #495057;
  text-wrap: nowrap;
}

.select-option-li:hover {
  background-color: #f5f5f5;
}

.select-option-li.selected {
  background-color: #e3eefa;
  color: #000000;
}
