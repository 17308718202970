.custom-pop-modal-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  outline: none !important;
  padding: 100px;
}

.modal-body-wrapper {
  border: 2px solid #0d6095 !important;
  padding: 35px 15px 5px 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  min-height: 350px;
  overflow-y: scroll;
}

.logo-container {
  width: 200px;
  position: absolute;
  top: 14px;
  left: 45px;
  background-color: white;
}

.custom-pop-modal {
  background: #fff !important;
  max-width: 700px !important;
  width: 100% !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
  position: relative !important;
  height: 450px;
  padding: 45px 20px 10px 20px;
}

.multi-pop-modal {
  background: #fff !important;
  max-width: 750px !important;
  width: 100% !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
  position: relative !important;
  height: 630px;
  padding: 45px 20px 20px 20px;
}

.multi-modal-body-wrapper {
  border: 2px solid #0d6095 !important;
  padding: 35px 15px 5px 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  height: 530px;
  overflow-y: scroll;
}

.custom-pop-close-button {
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.custom-pop-content {
  padding: 0 !important;
}

.custom-pop-header {
  display: flex !important;
  align-items: center !important;
  gap: 12px !important;
  margin-bottom: 16px !important;

  .custom-pop-warning-icon {
    color: #ff9800 !important;
  }
  .custom-pop-error-icon {
    color: #f44336 !important;
  }
  .custom-pop-success-icon {
    color: #4caf50 !important;
  }
  .custom-pop-info-icon {
    color: #2196f3 !important;
  }

  .custom-pop-title {
    font-weight: 600 !important;
    margin: 0 !important;
    font-size: 18px !important;
  }
}

.custom-pop-description {
  color: rgba(0, 0, 0, 0.7) !important;
  margin-bottom: 24px !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
}

.custom-pop-actions {
  gap: 10px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;

  .custom-pop-dont-show {
    .MuiFormControlLabel-label {
      font-size: 12px !important;
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }

  .custom-pop-primary-btn {
    background-color: #0066a1 !important;
    text-transform: none !important;
    padding: 6px 10px !important;
    font-weight: 500 !important;
    margin-left: 12px !important;

    &:hover {
      background-color: #005081 !important;
    }
  }

  .custom-pop-secondary-btn {
    color: #0066a1 !important;
    text-transform: none !important;
  }

  .custom-pop-dont-show-btn {
    color: gray !important;
    text-transform: none !important;
    &:hover {
      color: rgba(238, 87, 87, 0.993) !important;
    }
  }
}

.multi-popup-mail {
  position: absolute;
  bottom: 18px;
}

.notification-item {
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #fff;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .notification-content {
    display: flex;
    gap: 16px;
    align-items: flex-start;
  }

  .notification-icon {
    font-size: 20px;

    &.expiry {
      color: #f59e0b;
    }
    &.country {
      color: #0066a1;
    }
    &.update {
      color: #22c55e;
    }
  }

  .notification-details {
    flex: 1;
  }

  .notification-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
  }

  .notification-title {
    font-weight: 500;
    font-size: 14px;
  }

  .important-badge {
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;

    &--orange {
      background-color: rgba(245, 158, 11, 0.1);
      color: #f59e0b;
    }

    &--red {
      background-color: #f59c9666;
      color: #f44336;
    }
  }

  .notification-description {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    margin: 4px 0;
  }

  .notification-footer {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 30px;
    margin-top: 14px;
  }

  .dismiss-button {
    padding: 4px;
    color: rgba(0, 0, 0, 0.6);
    height: 30px;
  }
}
