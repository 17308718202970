#collapse-tree {
  height: 100%;
  overflow-y: overlay !important;

  &::-webkit-scrollbar {
    width: 8px !important;
  }

  .collapse-title {
    padding-left: 20px;

    #title-text {
      color: #666;
    }

    .title-icon {
      position: relative;
      top: 5px;
    }

    #side-icon {
      float: right;
      margin-right: 12px;
      cursor: pointer !important;
    }
  }

  .collapse-tree {
    // background-color: white;
    background-color: #ecf0f2;
    height: 100%;
    // padding: 20px;
    padding: 20px 20px 20px 0px;
    overflow-y: overlay !important;

    .collapse-header {
      padding-left: 10px;
      padding-right: 10px;
      height: 40px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2px;
      cursor: pointer;
      border: 1px solid rgb(0 0 0 / 10%);
      border-radius: 0px 7px 7px 0px;
      box-shadow: 1px 0px 3px 2px rgba(194, 216, 229, 1);

      .applied-bage {
        width: 100% !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .collapse__h6 {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          margin: 5%;
        }
      }
    }

    .inner-data-position {
      // margin-right: 30px;
      // float: right;
      font-size: 13px;
      margin-top: 11px;
      color: #323a46;
      font-weight: bolder;
      color: #6c757d !important;
    }

    .float-right {
      float: right;
      font-size: 14px !important;
      margin-top: 8px !important;
      color: #323a46;
      font-weight: bolder;
    }

    .class-total-sum {
      float: right;
      font-size: 14px !important;
      margin-top: 10px !important;
      color: #323a46;
      font-weight: bolder;
    }

    .total-sum {
      min-width: 50px !important;
      display: inline-block !important;
      margin: 0px !important;
      font-size: 14px !important;
      text-align: right !important;
    }

    .total-sum-parent {
      color: #323a46;
      position: absolute;
      top: -8px;
      right: 25px;
      font-weight: 500;
    }

    .collapse__NoFilters {
      font-size: 24px;
      color: #6c757d;
      font-weight: 500px !important;
    }
  }

  .spinner-filter {
    width: 70px;
    margin: 30px;
  }

  .spinner-filter > div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner-filter .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner-filter .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
.MuiTooltip-tooltip {
  ::-webkit-scrollbar {
    width: 6px !important;
  }
}
