#signup-page {
  


    .container {
      //   width: 100%;
      padding-right: 12px;
      padding-left: 12px;
      margin-right: auto;
      margin-left: auto;
      @media (min-width: 1200px) {
        max-width: 1140px;
      }

      .d-flex {
        display: flex;
        justify-content: center;
        margin: auto;

        .card-sign-up {
          width: 925px;
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          min-width: 0;
          word-wrap: break-word;
          background-color: #fff;
          background-clip: border-box;
          margin-bottom: 24px;
          -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
          box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
          height: 420px;
          border: 0 solid #f7f7f7;
          border-radius: 0.25rem;

          @media (max-width: 524px) {
            width: 100%;
          }

          .card-body {
            padding: 27px !important;

            .w-75 {
              width: 75% !important;
              @media (max-width: 348px) {
                width: 100% !important;
              }
            }
            .m-auto {
              margin: auto !important;
            }

            .col-flex {
              display: flex;
              @media (max-width: 600px) {
                display: block;
              }
            }
            .text-width-signUp {
              width: 97%;
            }
            .form-group {
              .form-control {
                box-sizing: content-box;
                height: 27px !important;
                display: block;
                width: 90%;
                margin: 10px 0px;
                height: calc(1.5em + 0.9rem + 2px);
                padding: 0.45rem 0.9rem;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                color: #6c757d;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.2rem;
                -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
                transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
                &:focus {
                  color: #495057;
                  background-color: #fff;
                  border-color: #b1bbc4;
                  outline: 0;
                  -webkit-box-shadow: none;
                  box-shadow: none;
                }
                @media (max-width: 600px) {
                  width: 90%;
                }
              }
              label {
                font-weight: 600;
                line-height: 1.5;
                color: #6c757d;
                text-align: left;
                font-size: 14px;
              }
              .custom-select {
                display: inline-block;
                width: 100%;
                margin: 10px 0px;
                height: calc(1.5em + 0.9rem + 2px);
                padding: 0.45rem 1.9rem 0.45rem 0.9rem;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                vertical-align: middle;
                background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
                  no-repeat right 0.75rem center/8px 10px;
                background-color: #fff;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                &:focus {
                  border-color: #b1bbc4;
                  outline: 0;
                  -webkit-box-shadow: none;
                  box-shadow: none;
                }
                @media (max-width: 600px) {
                  width: 95%;
                }
              }
              .btn-primary {
                cursor: pointer;
                background-color: "#005d91" !important;
                font-family: "Rubik" !important;
                color: #fff !important;
                width: 100%;
                height: 41px;
                background-color: #005d91;
                border-color: #005d91;
                font-size: 15px;
                border: none;

                &:hover {
                  color: #fff;
                  background-color: #07527d;
                  border-color: #07527d;
                }
              }
            }
          }
        }
      }

      .mb-4,
      .my-4 {
        margin-bottom: 2.25rem !important;
      }

      .mt-3,
      .my-3 {
        margin-top: 1.5rem !important;
      }

      .rowCards {
        display: flex;
        justify-content: center;
      }

      .text-danger {
        color: #f1556c !important;
      }

      .input {
        width: 400px !important;

        @media (max-width: 577px) {
          width: 100% !important;
        }
      }
      .h1,
      .h2,
      .h3,
      .h4,
      .h5,
      .h6,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 10px 0;
        font-size: 20px;
        color: #343a40;
      }

      .text-white-50 {
        color: rgba(255, 255, 255, 0.5) !important;
        .text-white {
          color: #fff !important;
          margin-left: 5px;
        }
      }
      a {
        color: #005d91;
        text-decoration: none;
        background-color: transparent;
        font-size: 14px;
      }

      .MuiOutlinedInput-input {
        height: 16px !important;
      }
    }
  
}
