.accordion {
  width: 99%;
  float: right;
  border-radius: 4px;
  margin-right: 15px;
  background-color: #ffffff !important;

  box-shadow: 1px 3px 3px 0px rgb(201 201 201) !important;
  //   border: none !important;
  margin-bottom: 10px;
  // padding-bottom: 10px;
}

.accordionSummary {
  padding: 10px;
  border-radius: 5px;
}

.flag {
  height: 25px;
  margin-right: 10px;
}

.countryName {
  margin-left: 0.5rem;
  font-size: 15px;
  font-weight: 300;
}

.importerIcon {
  color: #f62424;
}

.exporterIcon {
  color: #f62424;
}

.divider {
  display: inline-block;
  width: 0;
  height: 1.5em;
  border-left: 2px solid #ffffff;
  border-right: 1px solid #ffffff;
}

.sellers-buyers-value {
  background-color: #c2d8e5;
  width: 70px;
  text-align: center;
  height: 1.8em;
  font-size: 17px;
  color: #005d91;
  padding-top: 5px;
  margin-left: 10px;
  border-radius: 5px;
}