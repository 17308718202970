.profiler-cards {
  margin-top: 20px !important;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 16px;

    .title {
      font-size: 14px;
      font-weight: bold;
      color: #005d91;
      margin-bottom: 8px;
      text-transform: uppercase;
      border-bottom: 2px solid #c2d8e5;
      padding-bottom: 4px;
    }

    .value {
      font-size: 20px;
      font-weight: 600;
      color: #212529;
    }

    .detail {
      font-size: 14px;
      color: #495057;
      margin-top: 4px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #dce5ea;
      padding-bottom: 12px;
      margin-bottom: 12px;
    }

    .countries-available {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 8px;

      .country {
        background-color: #dce5ea;
        color: #005d91;
        font-size: 12px;
        font-weight: 500;
        padding: 4px 8px;
        border-radius: 16px;
        text-transform: capitalize;
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    .card {
      .value {
        font-size: 18px;
      }

      .detail {
        font-size: 12px;
      }
    }
  }
}
