.trade-dashboard-cards {
  margin-top: 20px !important;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .trade-card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .graph-container {
      justify-content: space-around;
    }

    .trade-title {
      font-size: 14px;
      font-weight: bold;
      color: #005d91;
      margin-bottom: 8px;
      text-transform: uppercase;
      padding-bottom: 4px;
    }

    .trade-value {
      font-size: 28px;
      font-weight: 600;
      color: #212529c2;
    }

    .trade-detail {
      font-size: 14px;
      color: #495057;
      margin-top: 4px;
    }

    .countries-available {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 8px;

      .country {
        background-color: #dce5ea;
        color: #005d91;
        font-size: 12px;
        font-weight: 500;
        padding: 4px 8px;
        border-radius: 16px;
        text-transform: capitalize;
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    .trade-card {
      .trade-value {
        font-size: 22px;
      }

      .trade-detail {
        font-size: 12px;
      }
    }
  }
}
