#recommendations {
  .card-box-recommendation {
    display: flex;
    flex-wrap: wrap;
  }

  span {
    margin-left: 6px;
    font-size: 18px;
  }

  .card-style {
    background: #d2d2d2;
    margin: 5px 6px;

    .css-46bh2p-MuiCardContent-root {
      padding: 5px 16px !important;

      .css-iy1xrw-MuiTypography-root {
        margin: 0;
        font-weight: 600 !important;
        margin-bottom: 0px;
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        text-align: center;
      }
    }

    .recommendation {
      max-width: 16rem;
      padding: 4px 9px;
      border: none;

      .recommendation-innner {
        margin-bottom: 0px !important;
        font-size: 14px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        // width:200px;
        white-space: nowrap;
        color: #000000d4;
        font-weight: 600;
      }

      @media (max-width: 1400px) {
        max-width: 10rem !important;
      }
      @media (min-width: 1400px) and (max-width: 1600px) {
        max-width: 11rem !important;
      }
      @media (min-width: 1600px) {
        max-width: 13rem !important;
      }
    }
  }
  .spinner {
    width: 70px;
    margin: 30px;
  }

  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
