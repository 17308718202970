#slider {
  //   height: 100vh;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .slider {
    position: relative;
    // width: 300px;
    width: 100%;
  }
  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }
  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 5px;
  }
  .slider__track {
    background-color: rgb(25, 118, 210);
    width: 100%;
    z-index: 1;
  }
  .slider__range {
    background-color: rgb(25, 118, 210);
    z-index: 2;
  }
  .slider__left-value,
  .slider__right-value {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    margin-top: 20px;
  }
  .slider__left-value {
    left: 6px;
  }
  .slider__right-value {
    right: -4px;
  }
  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    // width: 300px;
    width: 275px;
    outline: none;
  }
  .thumb--left {
    z-index: 3;
  }
  .thumb--right {
    z-index: 4;
  }
  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: rgb(25, 118, 210);
    border: 1px solid rgb(25, 118, 210);
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 21px;
    width: 21px;
    margin-top: 5px;
    pointer-events: all;
    position: relative;
  }
  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: rgb(25, 118, 210);
    border: 1px solid rgb(25, 118, 210);
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 21px;
    width: 21px;
    margin-top: 5px;
    pointer-events: all;
    position: relative;
  }
}
