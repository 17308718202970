.analyzeField-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-async {
    z-index: 100;
  }

  .analyzeBtn {
    padding: 20px;
    margin-right: -15px;
    display: flex;
    margin-left: auto;

    @media (max-width: 1344px) {
      align-items: center;
      justify-content: center;
    }

    .saveBtn {
      display: flex !important;
      margin-left: auto !important;
    }
    .btn-back {
      color: #fff !important;
      background-color: #0a6da6 !important;
      border-color: #005d91 !important;
      padding: 7px !important;
      margin-left: 10px;

      &:hover {
        color: #fff;
        background-color: #07527d !important;
        border-color: #07527d !important;
      }
    }
  }
}
