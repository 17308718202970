.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  .MuiPaper-root {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 0; /* Remove padding to control it more precisely */
    max-width: 500px;
    width: 90%; // Ensure responsiveness on smaller screens
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    max-height: 45vh; /* Constrain height to viewport */
  }

  .modal-header {
    border: 1px;
    border-radius: 7px 7px 0px 0px;
    font-size: 24px;
    font-weight: bold;
    padding: 20px; /* Add padding to the header */
    background-color: #f5f5f5; /* Ensure the header background matches the modal */
    border-bottom: 1px solid #ccc; /* Add a bottom border for separation */
    position: sticky;
    top: 0;
    z-index: 10;
    border-top: 4px solid rgb(66, 192, 251);
  }

  .modal-body {
    flex: 1;
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 20px;
    line-height: 25px;
  }

  .modal-footer {
    border: 1px;
    border-radius: 0px 0px 7px 7px;
    display: flex;
    justify-content: flex-end;
    padding: 10px; /* Add padding to the footer */
    background-color: #f5f5f5; /* Ensure the footer background matches the modal */
    border-top: 1px solid #ccc; /* Add a top border for separation */

    .button {
      background-color: rgb(66, 192, 251);
      color: white;
      font-weight: 600;
    }
  }

  .MuiButton-root {
    margin-left: 10px;
  }
}
