.logistic-record-card {
  width: 340px !important;
  min-width: 320px !important;
  max-width: 400px;
  padding: 16px 16px 6px 16px !important;
  border-radius: 8px !important;
  background: #fff !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
  font-family: "Arial", sans-serif !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #e1e1e1 !important;
}

.logistic-card-content {
  display: flex !important;
  flex-direction: column !important;
  gap: 5px !important;
}

.logistic-header {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}

.logistic-flag {
  width: 30px !important;
  height: 20px !important;
  border-radius: 2px !important;
}

.logistic-card-name {
  font-size: 16px !important;
  font-weight: bold !important;
  margin: 0 !important;
  color: #2a2a2a !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  background-color: #c2d8e57a;
  padding: 4px 8px;
  border-radius: 4px;
}

.logistic-type {
  font-size: 14px !important;
  color: #777 !important;
  text-transform: uppercase !important;
  margin: 5px 0 !important;
}

.logistic-country-name {
  font-size: 14px !important;
  font-weight: bold !important;
  margin: 5px 0 !important;
  color: #777 !important;
}

.logistic-records {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #333 !important;
  margin: 6px 0 !important;
}

.logistic-status-container {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between;
  margin-top: 4px !important;
}
.logistic-status {
  display: flex !important;
  align-items: center !important;
  gap: 6px !important;

  .logistic-icon {
    font-size: 18px !important;
  }

  .logistic-completed {
    color: green !important;
  }

  .logistic-pending {
    color: orange !important;
  }
  .logistic-failed {
    color: red !important;
  }
}

.logistic-status-text {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #333 !important;
  cursor: pointer;
}

.logistic-created-at {
  font-size: 12px !important;
  color: #666 !important;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.logistic-pending-loader {
  animation: rotate 1.5s linear infinite; // Adjust duration as needed
}

.retry-btn {
  background-color: #158e76;
  border: 1px solid #158e76;
  color: #ffffff;
  border-radius: 2px;
  padding: 2px 8px;

  &:hover {
    background-color: #19aa8d;
    border: 1px solid #19aa8d;

    cursor: pointer;
  }
}

.delete-download {
  color: #ff00009e;
  cursor: pointer;
  transition: transform 0.6s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
}
