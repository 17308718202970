#view-column {
  .sidebar-close {
    position: absolute;
    right: 12px;
    cursor: pointer;
  }
  .sidebar-title {
    position: sticky;
    top: 0px;
    z-index: 100;
    background-color: #005d91;
    padding: 22px 25px;
    color: #fff;
  }

  .modal-sub-title {
    display: flex;
    height: 60px;
    width: 100%;
    align-items: flex-end;
    position: sticky;
    top: 76px;
    background-color: white;
    padding: 0px 24px;
    z-index: 500;
    .modal-checkbox-header-div {
      display: flex;
      width: 100%;
      height: 40px;
      .modal-check-btn {
        background-color: #c2d8e5;
        width: 20%;
        height: 100%;
        margin-right: 5px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
      }
      .modal-check-lable {
        background-color: #c2d8e5;
        width: 80%;
        height: 100%;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: 5px;
        .check-btn-lable {
          color: #005d91;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}
