.download-request-summary-container {
  padding: 8px;

  .summary-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .summary-content {
    display: flex;
    gap: 16px;

    .points-section,
    .download-section {
      flex: 1;
      padding: 0px 16px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #d3d3d31c;

      .points-header,
      .download-header {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 12px;

        .points-icon,
        .download-icon {
          color: #005d91d1;
        }

        .section-title {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .points-text,
      .download-text {
        font-size: 14px;
        margin: 10px 0;
        display: flex;
        justify-content: space-between;

        span {
          font-weight: 600;
        }

        .points-value {
          color: green;
        }
      }

      .points-locked {
        color: #f39c12;
      }

      .points-deducted {
        color: #e74c3c;
      }

      .info-icon {
        color: #f39c12;
        width: 18px;
      }

      .download-progress-bar {
        margin-top: 8px;
        height: 6px;
        border-radius: 4px;
        background-color: #e0e0e0;
      }

      .download-progress-text {
        font-size: 12px;
        text-align: right;
        margin-top: 4px;
      }
    }
  }

  .locked-points-section {
    margin-top: 16px;
    padding: 14px;
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-direction: column;
    height: 100px;

    .header-lock-detail {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      height: 20px;
      align-self: flex-start;

      .locked-icon {
        color: #f39c12;
      }

      .locked-title {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .locked-description {
      font-size: 12px;
      color: #666;
      padding: 0px 4px;
    }
  }
}
