#explore-filter-box {
  // padding: 0px 16px;
  // margin-bottom: 25px;
  // padding-bottom: 8px;
  // border-bottom: 1px solid #0000001a;
  #filters-btn {
    margin: 7px 15px;
  }

  .apply-filter-label {
    margin-left: 4px !important;
    font-size: 12px !important;
  }

  .content-between-border {
    margin-top: 22px;
    margin-bottom: 53px;
  }

  .set-border {
    border-bottom: 1px solid rgb(214, 214, 214);
    margin-top: 23px;
  }

  .filter-title {
    // padding-top: 12px !important;
    padding-left: 0px;
    padding: 15.5px 18px;
    background: #c2d8e5;
    border-radius: 0px 8px 0px 0px !important;

    #filter-text {
      color: rgb(108, 117, 125);
    }

    #filter-title {
      display: flex;
      align-items: center;
      color: #005d91;
      margin: 0;
    }
  }

  #applied-filter {
    margin-left: auto;
    margin-right: 21px;
  }

  .filter-btn {
    padding-top: 20px !important;
    margin-top: -15px !important;
    padding-left: 0px;
    padding-right: 5px;
  }

  .apply-filter-btn {
    margin-top: -15px !important;
    padding-top: 20px !important;
    padding-left: 12px !important;
  }
}
