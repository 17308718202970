#login-password {
  .container {
    //   width: 100%;
    padding-right: 12px;
    padding-left: 12px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 1200px) {
      max-width: 1140px;
    }

    .rowCards {
      display: flex;
      justify-content: center;

      .card-login {
        width: 460px;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        margin-bottom: 24px;
        -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
        box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
        height: auto;
        border: 0 solid #f7f7f7;
        border-radius: 0.25rem;
        @media (max-width: 524px) {
          width: 100%;
        }

        .card-body {
          padding: 27px !important;
          position: relative;

          .login-type-btn-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #f4f4f5;
            border-radius: 5px;
            padding: 4px 4px;
            background-color: #f4f4f5;
            margin-top: 20px;
            color: black !important;

            .login-type-btn {
              border: none;
              padding: 7px 40px;
              cursor: pointer;
              color: black !important;
              transition: all ease-in 0.2s;
              border-radius: 5px;

              .login-btn-text {
                color: #086294 !important;

                &.active-text {
                  color: white !important;
                }
              }

              &:hover {
                background-color: #f0f0f0;
              }

              &.active-btn {
                background-color: #086294;
              }
            }
          }
          // .password-strength-div {
          //   position: absolute;
          //   top: 0px;
          //   background-color: white;
          //   -webkit-box-shadow: 0px 1px 12px -3px rgb(0 0 0);
          //   -moz-box-shadow: 0px 1px 12px -3px rgba(0, 0, 0, 1);
          //   box-shadow: 0px 1px 12px -3px rgb(0 0 0);
          //   padding: 10px;
          //   top: 257px;
          //   border-radius: 5px;
          // }

          .w-75 {
            width: 75% !important;
            @media (max-width: 348px) {
              width: 100% !important;
            }
          }

          .m-auto {
            margin: auto !important;
          }

          .mb-4,
          .my-4 {
            margin-bottom: 2.25rem !important;
          }

          .mt-3,
          .my-3 {
            margin-top: 1.5rem !important;
          }

          label {
            font-weight: 600;
            line-height: 1.5;
            color: #6c757d;
            text-align: left;
          }
        }
      }
    }
  }

  .mb-2,
  .my-2 {
    margin-bottom: 1rem;
  }

  .form-control-login {
    box-sizing: content-box;
    height: 27px !important;
    display: block;
    width: 92.2%;
    margin: 10px 0px;
    height: calc(1.5em + 0.9rem + 2px);
    padding: 0.45rem 0.9rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #b1bbc4;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    @media (max-width: 600px) {
      width: 90%;
    }
  }

  .input {
    width: 400px !important;
    @media (max-width: 577px) {
      width: 100% !important;
    }
  }

  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  a {
    color: #005d91;
    text-decoration: none;
    background-color: transparent;
    font-size: 14px;
  }
  .text-white {
    color: #fff !important;
    margin-left: 5px;
  }
  .MuiOutlinedInput-input {
    height: 16px !important;
  }
}
