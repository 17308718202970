#exploreModal {
  max-height: 600px;
  overflow: scroll;

  .details {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    align-items: center;
    font-weight: 800;
    cursor: pointer;
    height: 40px;
    background: #c2d8e5;
    margin-top: 15px;
    color: #005d91;

  }

  .table-head {
    font-weight: 700;
    color: #303336;
    // width: 18vw;
    max-width: 14vw;
  }

  .cmp-table-head {
    font-weight: 700;
    color: #303336;
  }

  .set-border {
    border: 1px solid lightgrey;
    background-color: #ecf3f7;
  }

  .fav-icon {
    cursor: pointer !important;
  }
}