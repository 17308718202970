.trade-dashboard-container {
  display: flex;
  // height: calc(100vh - 358px) !important;
  height: 100% !important;

  // @media (min-width: 769px) and (max-width: 1199px) {
  //   height: calc(100vh - 210px) !important;
  // }

  .trade-sidebar {
    width: 240px !important;
    background-color: #e5eef4 !important;
    color: #6c757d !important;
    display: flex !important;
    flex-direction: column !important;
    transition: width 0.3s ease !important;
    // box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1) !important;
    // height: calc(100vh - 121px) !important;
    height: 100% !important;
    overflow: hidden !important;
    justify-content: space-between !important;
    color: #005d91 !important;

    // @media (min-width: 1200px) and (max-width: 1599px) {
    //   height: calc(100vh + 160px) !important;
    // }

    // @media (min-width: 769px) and (max-width: 1199px) {
    //   height: calc(100vh + 160px) !important;
    // }

    // @media (min-width: 1600px) and (max-width: 1719px) {
    //   height: calc(100vh - 67px) !important;
    // }

    // @media (max-width: 768px) {
    //   height: calc(100vh + 161px) !important;
    // }

    // // height changes

    // @media (max-height: 770px) {
    //   height: calc(100vh + 140px) !important;
    // }

    // @media (max-height: 710px) {
    //   height: calc(100vh + 118px) !important;
    // }

    // @media (max-height: 670px) {
    //   height: calc(100vh + 100px) !important;
    // }

    // @media (max-height: 620px) {
    //   height: calc(100vh + 88px) !important;
    // }

    &.trade-collapsed {
      width: 60px !important;

      .trade-tab-item {
        padding: 0 !important;
        justify-content: center !important;
        text-align: center !important;

        .MuiSvgIcon-root {
          margin-right: 0 !important;
        }

        .trade-tab-text {
          display: none !important;
        }
      }
    }

    .trade-toggle-btn {
      width: 90%;
      background: #005d91 !important;
      color: #ffffff !important;
      font-size: 15px !important;
      cursor: pointer !important;
      margin: 10px !important;
      padding: 5px !important;
      border-radius: 5px !important;
      transition: background-color 0.3s ease !important;

      &:hover {
        background-color: #004d7a !important;
      }
    }

    .trade-tab-list {
      list-style: none !important;
      padding: 0 !important;
      margin: 0 !important;
      margin-top: 20px !important;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .trade-tab-item {
        display: flex !important;
        align-items: center !important;
        padding: 15px 15px !important;
        cursor: pointer !important;
        font-size: 14px !important;
        border-left: 5px solid transparent !important;
        transition: background-color 0.3s ease, border-color 0.3s ease !important;
        position: relative !important;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1) !important;
        border-end-start-radius: 10px;
        color: #005d91cc !important;

        .MuiSvgIcon-root {
          font-size: 24px !important;
          margin-right: 7px !important;
        }

        .trade-tab-text {
          flex: 1 !important;
        }

        &:hover {
          // background-color: #dce5ea !important;
          background-color: #005c91c2 !important;
          color: #bfe5fc !important;
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15) !important;
        }

        &.active-trade {
          background-color: #ffffff !important;
          color: #005d91 !important;
          border-left: 5px solid #005d91 !important;
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15) !important;
        }

        &.profile {
          padding: 10px 16px !important;
          margin: 0px 8px !important;
          border-radius: 8px !important;
          margin-bottom: 18px !important;

          &.active-trade {
            background-color: #87b5cf !important;
            color: #005d91 !important;
            border-left: 5px solid #005d91 !important;
          }
        }
      }
    }
  }

  .trade-sidebar-table-container {
    flex: 1 !important;
    width: 80% !important;
    padding: 0px 25px !important;

    overflow-y: scroll !important;
    // height: calc(100vh + 150px);
    height: 100% !important;

    // @media (min-width: 1600px) {
    //   height: calc(100vh - 72px) !important;
    // }

    // @media (min-width: 1720px) {
    //   height: calc(100vh - 128px) !important;
    // }

    // // height change

    // @media (min-height: 731px) and (max-height: 765px) {
    //   height: calc(100vh + 126px) !important;
    // }

    // @media (min-height: 621px) and (max-height: 730px) {
    //   height: calc(100vh + 95px) !important;
    // }

    // @media (min-height: 600px) and (max-height: 620px) {
    //   height: calc(100vh + 65px) !important;
    // }

    // @media (min-height: 500px) and (max-height: 599px) {
    //   height: calc(100vh + 70px) !important;
    // }
  }
}
