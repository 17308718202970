.text-user {
  margin: 0px;
}

.user-mt-5 {
  margin: 10px 0px !important;
}

#edit-body {
  display: flex;
  flex-direction: column;
}

#select-unselect-btn {
  background-color: #f1f5f7;
  width: 50%;
  text-align: center;
  color: #323a46;
  padding: 0.28rem 0.8rem;
  cursor: pointer;

  &:hover {
    background-color: #d1dee4;
  }
}

#manage-users {
  .manage-users-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;

    .manageuser-limit-btn {
      display: flex;
      align-items: center;

      .manageuser-limit {
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 770px) {
  #manage-users {
    .manage-users-header {
      display: block;
      align-items: center;
      margin: 20px 0px;

      .manageuser-limit-btn {
        display: block;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .manageuser-limit {
          margin: 10px 0px;
        }
      }
    }
  }
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: #1976d27a !important;
}
