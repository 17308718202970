.toggleButtonGroup {
  display: flex !important;
  justify-content: flex-start !important;
  padding: 10px 20px !important;
  gap: 12px !important;

  .toggleButton {
    background-color: #f1f3f4 !important;
    color: #707070 !important;
    padding: 5px 15px !important;
    text-transform: none !important;
    font-weight: 600;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    cursor: pointer !important;

    &:hover {
      background-color: #e2e6ea !important;
    }

    .countBadge {
      background-color: #2f2f2f !important;
      color: #ffffff !important;
      border-radius: 12px !important;
      padding: 0px 8px !important;
      font-size: 12px !important;
      font-weight: normal !important;
    }

    .unreadCountBadge {
      background-color: #005d91 !important;
      color: white !important;
      border-radius: 12px !important;
      padding: 0px 8px !important;
      font-size: 12px !important;
      font-weight: normal !important;
    }
  }

  .activeButton {
    background-color: #bfd6e3 !important;
    color: black !important;
  }
}
