.workspace-card-chartBox {
  background-color: #fff;
  padding: 1.5rem;
  -webkit-box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
  box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
  margin-bottom: 24px;
  border-radius: 0.25rem;
  .workspace-card-header-chartBox {
    background-color: #005d91 !important;
    padding: 1.5rem;
    color: #fff !important;
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
  .close-minimize {
    float: right;
    position: relative;
  }
  #card-icons {
    cursor: pointer;
  }
  .set-card-minimize-position {
    position: absolute !important;
    right: 28px !important;
    top: -6px !important;
  }
  .set-card-plus-position {
    position: absolute !important;
    right: 28px !important;
  }
  .workspace-card-body-chartBox {
    padding: 1rem;
    border: 2px solid #005d91;
  }
  .chart-icon {
    border-top: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    padding: 6px 2px;
    border-radius: 5px;
    color: #ffffff;

    &:hover {
      background-color: #ffffff !important;
      color: #005d91 !important;
      transition: 0.3s;
    }
  }
}
