.list_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}
.list_container_country {
  display: grid;
  grid-template-columns: repeat(2, 1fr) !important;
  grid-gap: 5px;
  margin-top: 10px;
  max-height: 400px;
  min-height: 200px;
  overflow: overlay !important;
  overflow: auto;
}
#collapse-filter {
  // height: 100%;

  .btn-parent {
    // display: flex !important;
    // gap: 12px !important;
    // border-bottom: 1px solid lightgrey;

    #refresh-btn {
      &:hover {
        // background-color: #323a46;
        // color: white;
      }
      // top: 2px;
      // padding: 9px 16px;
      border: 1px solid black;
      border-radius: 5px;
      position: relative;
      left: 20px;
      cursor: pointer;
      #refresh-icon {
        color: #3891c3;
      }
    }
  }

  .country_and_port {
    padding-left: 8px;
  }
  .total-sum-parent {
    position: absolute;
    top: -8px;
    right: 20px;
  }
  .hs_code_and_month {
    padding-bottom: 1rem !important;
  }

  .parent-hscode-month {
    pointer-events: none;
  }
  .children-hscode-month {
    pointer-events: none;
  }
  .items_container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-auto-flow: column;
  }

  .checked-data {
    position: absolute;
    top: 7px;
    right: 57px;
  }
  .btn-viewmore {
    margin-left: 10px;
    font-size: 19px;
    margin-top: 10px !important;
    color: #154865;
    text-align: end;
    margin-right: 19px;
    border-bottom: 1px solid #154865;
    display: inline-block;
    margin-bottom: 0px;
    cursor: pointer;
  }

  .btn-viewmore-children {
    margin-left: 27px;
    font-size: 19px;
    margin-top: 2px !important;
    color: #154865;
    text-align: end;
    margin-right: 19px;
    border-bottom: 1px solid #154865;
    display: inline-block;
    margin-bottom: 4px;
    cursor: pointer;
  }
  .btn-viewmore-subchild {
    margin-left: 56px;
    font-size: 19px;
    margin-top: 2px !important;
    color: #154865;
    text-align: end;
    margin-right: 19px;
    border-bottom: 1px solid #154865;
    display: inline-block;
    margin-bottom: 4px;
    cursor: pointer;
  }
}
