.info-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #e1e1e1;
  padding: 13px;
  max-width: 300px;
  max-height: 250px;

  .save-query-title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    background-color: #ddeaf1;
    color: black;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    text-transform: uppercase;
  }

  .info-card-date {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 0.9em;
    color: #888;

    .icon {
      margin-right: 5px;
      margin-top: 3.5px;
    }
  }

  .info-card-details {
    margin-top: 2px;
    background-color: #f1f5f8;
    border-radius: 5px;
    padding: 5px 10px;

    .save-query-card-details {
      list-style: disc !important;
      padding-left: 20px !important;
      margin: 5px 0 !important;

      .save-query-li {
        margin: 5px 0;
        font-size: 0.9em;
        display: block !important;
        .bullet_icon {
          font-size: 8px !important;
          margin-right: 10px !important;
        }
      }
    }
  }
}

.flex-x-y-axis-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
