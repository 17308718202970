#selectComponent-forExplore {
  .selectComponent-container {
    .title-div {
      // width: 12%;
      margin-right: 10px;
    }

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding-top: 7px;

    @media screen and (max-width:700px) {
      flex-direction: column !important;
      align-items: start;
    }

    @media screen and (max-width:800px) {
      flex-direction: column !important;
      align-items: start;
    }

    .select-container-div {
      display: flex;
      flex-wrap: wrap;
      // flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width:700px) {
        flex-direction: column !important;
        align-items: start;
      }

      // flex: 1;
    }

    .explore-date-container {
      display: flex;
      align-items: center;
      margin-left: 8px;

      .select-container-item {
        display: flex;
        flex-direction: column;
      }

      .select-item-label {
        font-weight: 500;
        margin-bottom: 7px;
      }


    }

    .select-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .select-container-item {
        display: flex;
        flex-direction: column;

        .select-item-label {
          margin-left: 10px;
          font-weight: 500;
        }

        .search-form-control {
          height: 40px !important;
        }

        .select-search-On {
          max-width: 15vh !important;

          @media screen and (max-width:1000) {
            width: 12vh !important;
          }
        }
      }
    }

    .set-red-setTrade-alert {
      color: red !important;
      font-weight: 700;
      font-size: 20px;
      margin-left: 0.425rem;
    }
  }
}