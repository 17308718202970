.password-main {
  font-size: 14px;
  color: #676a6c;
  .error-icons {
    margin-bottom: -5px;
    font-size: 20px !important;
    margin-right: 5px;
  }
  .text-danger {
    color: #f1556c;
    margin-top: 5px;
    // margin-bottom: 6px;
    padding-left: 3px;
    font-size: 14px;
  }
  .text-success {
    color: green;
    margin-top: 5px;
    // margin-bottom: 6px;
    padding-left: 3px;
    font-size: 14px;
    // display: none;
  }
}

.d-block {
  display: block;
}
.d-none {
  display: none;
}
