// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Source+Code+Pro:wght@400;600&display=swap");

.markdown-container {
  padding: 16px;
  font-family: "Inter", sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  overflow-x: auto;
  line-height: 1.8;

  h1,
  h2,
  h3 {
    font-family: "Inter", sans-serif;
    color: #005d91;
    margin-bottom: 8px;
    font-weight: 600;
  }

  a {
    color: #005d91;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  table {
    text-align: center;
    width: 100%;
    border-collapse: collapse;
    margin: 16px 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;

    thead {
      background-color: #c2d8e5;
      color: #000000bd;

      th {
        background-color: #c2d8e5;
        color: #000000bd;
      }

      th {
        padding: 12px 16px;
        font-weight: bold;
        border: 1px solid #ddd;
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f2f2f2;
        }

        &:hover {
          background-color: #e6f7ff;
        }

        td {
          padding: 12px 16px;
          border: 1px solid #ddd;

          code {
            font-family: "Source Code Pro", monospace;
            background-color: #c2d8e563;
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 0.9rem;
            display: inline-block;
          }
        }
      }
    }
  }
  ul {
    padding-left: 20px !important;
    margin-left: 20px !important;
    list-style-type: disc !important;
    margin-bottom: 16px !important;
    li {
      font-size: 1rem !important;
      line-height: 1.6 !important;
      display: list-item !important;
      position: static !important;
      text-align: inherit !important;

      code {
        font-family: "Source Code Pro", monospace;
        background-color: rgba(194, 216, 229, 0.3882352941);
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 0.9rem;
        display: inline-block;
      }
    }
  }
}

.tabs {
  display: flex;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
  padding: 12px 0;
  margin-bottom: 5px;

  .tab-button {
    padding: 10px 12px;
    margin-right: 16px;
    background-color: #ffffff;
    border: 2px solid #dce5ea;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #005d91;
    cursor: pointer;
    transition: all 0.3s ease;

    &.active-tab {
      background-color: #005c91e7 !important;
      color: #fff;
      border: 2px solid #dce5ea;
    }

    &:hover {
      background-color: #e0e0e0;
    }
  }
}

.code-card {
  position: relative !important;

  .copy-btn {
    position: absolute !important;
    top: 13px;
    right: 10px;
    font-size: 1px;
    background-color: transparent;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      color: whitesmoke;
    }
  }

  .language-dropdown {
    position: absolute !important;
    top: 10px;
    right: 50px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }

  .language-dropdown:focus {
    outline: none;
    border-color: #007bff;
  }
}
