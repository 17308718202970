.card-container {
  width: 100%;
  //   min-height: 300px !important;
  padding-top: 20px !important;
  padding-left: 20px !important;

  .card-box-country {
    background-color: #fff;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 10px 10px 16px 10px; // change
    height: 100%;

    .card-header {
      display: flex;
      align-items: center;

      span {
        font-weight: bold;
        margin-left: 8px;
      }

      .ribbon-box {
        position: relative;
      }

      .ribbon-success-trade-lable {
        width: 0;
        height: 0;
        border-right: 13px solid #1abc9c;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;

        @media (max-width: 1600px) {
          border-bottom: 15px solid transparent;
        }
      }

      .square {
        width: 0;
        height: 0;
        border-right: 10px solid #158e76;
        border-top: 10px solid transparent;
        transform: scale(-1);
        position: absolute;
        bottom: -33%;
        right: 0px;
      }

      .ribbon-trade-lable {
        display: inline-flex;
        position: relative;
        z-index: 1;
        right: -19px;
      }

      .rebbon-text-trade-lable {
        background: rgb(26, 188, 156);
        background: linear-gradient(
          80deg,
          rgba(26, 188, 156, 1) 59%,
          rgba(217, 217, 217, 1) 100%
        );
        width: 80px;
        height: 26px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: white;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
      }

      .ribbon-success-trade-lable-1 {
        width: 0;
        height: 0;
        border-right: 11px solid #158e76;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;

        @media (max-width: 1600px) {
          border-right: 10.5px solid #158e76;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
        }
      }

      .ribbon-trade-lable-1 {
        display: flex;
        position: absolute;
        left: -5px;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
      }

      .rebbon-text-trade-lable-none {
        background: #158e76;
        width: 80px;
        height: 22px;
      }
    }

    .card-content {
      padding: 5px; //change
      width: 100%;
      height: 86%;

      .card-content-left {
        .card-content-left-div {
          border: 1px solid rgba(0, 0, 0, 0.275);
          margin-right: 2px;
          border-radius: 5px;
          height: 100%;
        }

        @media (min-width: 650px) and (max-width: 768px) {
          .field_container {
            margin-left: 8px !important;
          }
        }

        @media (min-width: 600px) and (max-width: 650px) {
          .field_container {
            margin-left: 3px !important;
          }
        }

        @media (max-width: 360px) {
          .field_container {
            margin-left: 3px !important;
          }
        }

        li {
          list-style: circle !important;
          display: flex !important;
          color: #3e3e3e !important;
          font-size: 13px;
          align-items: center;
          margin: 10px 0px !important;

          .showcase-fields-bullete-point {
            width: 15px;
            height: 15px;
            color: #3e3e3e;
            margin-right: 5px;
          }
        }

        @media (min-width: 1351px) and (max-width: 1600px) {
          li {
            font-size: 13px;
          }
        }

        @media (max-width: 1350px) {
          li {
            font-size: 11px;
          }
        }
      }

      .card-content-right {
        .field_value,
        .field_name {
          display: block !important;
          color: #3e3e3e !important;
          font-size: 13px;
          margin: 15px 0px !important;

          @media (min-width: 1351px) and (max-width: 1600px) {
            font-size: 13px;
          }

          @media (max-width: 1350px) {
            font-size: 11px;
          }
        }

        @media (min-width: 1350px) and (max-width: 1470px) {
          .field_container_right {
            margin-left: 0px !important;
          }
        }

        @media (min-width: 650px) and (max-width: 768px) {
          .field_container_right,
          .field_container_left {
            margin-left: 8px !important;
          }
        }

        @media (min-width: 600px) and (max-width: 650px) {
          .field_container_right,
          .field_container_left {
            margin-left: 3px !important;
          }
        }

        @media (max-width: 360px) {
          .field_container_right,
          .field_container_left {
            margin-left: 3px !important;
          }
        }

        .card-content-right-div {
          border: 1px solid rgba(0, 0, 0, 0.275);
          margin-left: 2px;
          border-radius: 5px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .view-button-container {
          .view_button {
            background-color: #ffffff;
            border-top: 0.5px solid #005d91 !important;
            border-right: 3px solid #005d91 !important;
            border-bottom: 3px solid #005d91 !important;
            border-left: 0.5px solid #005d91 !important;
            color: #005d91 !important;
            border: none;
            outline: none;
            border-radius: 5px;
            border: 1px solid #005d91;
            padding: 3px 15px !important;

            span {
              margin-right: 4px;
            }

            &:hover {
              background-color: #005d91 !important;
              border-color: #005d91;
              color: #ffffff !important;
              transition: 0.3s;
            }
          }
        }
      }
    }
  }
}

.rebbon-text-trade-lable > font {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// @media screen and (min-width: 1600px) and (max-width: 1800px) {
//   .right-content-right ul {
//     margin-left: 10px !important;
//   }
//   .right-content-left ul {
//     margin-left: 10px !important;
//   }
// }
@media screen and (min-width: 900px) and (max-width: 1350px) {
  .right-content-right ul:not(.arrow) {
    margin-left: 5px !important;
  }

  .right-content-left ul:not(.arrow) {
    margin-left: 5px !important;
  }
}
