#country-shipment {
  #title {
    background-color: #f1f5f7;
    padding: 12px;
    color: #343a40 !important;
    font-size: 15px;
    font-weight: 600;
    position: relative;
    #sub-title {
      position: absolute;
      top: 15px;
      margin-left: 5px;
    }
  }
  #shipment-records {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    border: 1px solid #0b6496;
    @media (max-width: 500px) {
      display: block;
    }
    #record-data {
      display: grid;
      margin: 10px;
      .workSpace_tablecell {
        border-bottom: none;
        padding: 5px;
        max-width: 250px !important;
        min-width: 250px !important;
      }

      #downArrow {
        position: relative;
        top: 3px;
        // margin-right: 5px;
        color: #f50025;
      }
      #upArrow {
        color: green !important;
        position: relative;
        top: 3px;
      }
    }
  }
  #shipment-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    #limit-info {
      position: relative;
    }
    .buy-btn {
      color: #005d91;
      width: 100%;
      height: 35px;
      background-color: white;
      border-radius: 28px;
      font-size: 15px;
      border: 1px solid #005d91;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #005d91;
        color: white;
      }
      #set-text-position {
        margin-left: 5px;
      }

      @media (max-width: 500px) {
        margin-top: 10px;
      }
    }

    #credit-badge {
      background-color: rgba(241, 85, 108, 0.25);
      color: #f1556c !important;
      font-size: 14px;
      padding: 3px;
      border-radius: 4px;
      font-weight: 700;
      margin: 10px;

      @media (max-width: 500px) {
        margin: auto;
        margin-top: 10px;
      }
    }
  }
}
