#notification-drawer {
  .MuiDrawer-paper {
    width: 380px;
    max-width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: white;
    box-shadow: 24px;
    outline: none;
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }

  #notification-modal {
    width: 100%;
    font-family: "Rubik" !important;

    .sidebar-close {
      position: absolute;
      right: 12px;
      cursor: pointer;
    }

    .sidebar-title {
      background-color: #005d91;
      padding: 22px 25px;
      color: #fff;
    }

    #notification-list {
      height: calc(100vh + 149px);
      overflow: auto;
      padding: 10px 18px;

      @media (min-width: 1720px) {
        height: calc(100vh - 133px);
      }

      @media (min-width: 1600px) and (max-width: 1720px) {
        height: calc(100vh - 78px);
      }

      .unRead-noti-section {
        background-color: #e5e7eb;
        margin: 8px 0px;
        padding: 10px;
        position: relative;

        &:hover {
          background-color: #b8c5de;
        }
      }

      .read-noti-section {
        border: 2px solid #e5e7eb;
        margin: 8px 0px;
        padding: 10px;
        position: relative;

        &:hover {
          background-color: #b8c5de;
        }
      }

      .circle {
        height: 10px;
        width: 10px;
        background-color: #005d91;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
}
