$dot-size: 8px;
$blue-color: #005d91;
$green-color: #00cc66;

.center-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px;
}
.loader-cross {
  text-align: center;
  position: relative;
  width: 50px; // Adjust the width
  height: 50px; // Adjust the height

  .dot {
    position: absolute;
    width: $dot-size;
    height: $dot-size;
    animation: bounce 2s infinite;
    background-color: $blue-color;

    &:nth-child(2n) {
      background-color: $green-color;
    }
  }

  // Position the dots to form a cross layout
  .dot:nth-child(1) {
    top: 0;
    left: 0;
    transform: translateX(-50%);
    animation-delay: 0s;
  }

  .dot:nth-child(3) {
    top: 25%;
    left: 25%;
    animation-delay: 0.2s;
  }

  .dot:nth-child(5) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-delay: 0.4s;
  }

  .dot:nth-child(2) {
    top: 75%;
    left: 75%;
    animation-delay: 0.4s;
  }

  .dot:nth-child(4) {
    top: 100%;
    left: 100%;
    animation-delay: 0.6s;
  }

  .dot:nth-child(9) {
    top: 100%;
    left: 0;
    animation-delay: 0s;
  }

  .dot:nth-child(7) {
    top: 75%;
    left: 25%;
    animation-delay: 0.2s;
  }

  .dot:nth-child(6) {
    top: 25%;
    left: 75%;
    animation-delay: 0.4s;
  }

  .dot:nth-child(8) {
    top: 0;
    left: 100%;
    animation-delay: 0.6s;
  }
}

// Keyframes for left-to-right bounce animation
@keyframes bounce {
  0%,
  80%,
  100% {
    transform: translateX(-100%) scale(0); // Start from left side and scale down
  }
  40% {
    transform: translateX(0) scale(1); // Move to center and scale up
  }
}
