#countryExplore {
  padding: 0px 5% !important;

  @media (max-width: 1600px) {
    padding: 0px 3% !important;
  }

  @media (max-width: 762px) {
    padding: 0px 2% !important;
  }

  .main_container {
    .row_dashboard {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .text {
        margin: 26px 3px !important;
        font-family: "Rubik" !important;
        font-size: 1.25rem !important;

        span {
          margin-left: 0px !important;
        }

        @media (max-width: 700px) {
          width: 100%;
          margin: 13px 3px !important;
        }
      }

      .trade_type_container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .active {
          transform: scale(1.12);
          background-color: #005d91;
          color: #fff !important;

          // box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.7);
          // -webkit-box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.7);
          // -moz-box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.7);
          @media (max-width: 1350px) {
            transform: scale(1.22);
          }
        }

        .btn {
          border: 1px solid #005d91;
          color: #005d91;
          font-weight: 600 !important;
          font-size: 14px;
        }

        .btn-all {
          margin-right: 20px !important;

          @media (max-width: 1350px) {
            font-size: 10px !important;
          }

          @media (max-width: 360px) {
            margin-right: 10px !important;
          }
        }

        .import_export_btn_container {
          border: 1px solid #005d91;
          border-radius: 4px;
        }

        .import_export_btn {
          color: #005d91;
          font-weight: 600 !important;
          font-size: 14px;

          .import_export_btn > button :nth-child(1) {
            border-right: 1px solid #005d91 !important;
            border-radius: 1px;
          }

          @media (max-width: 1350px) {
            font-size: 10px !important;
          }
        }

        @media (max-width: 463px) {
          margin-left: 4px !important;
        }
      }

      .country_switch {
        .dFlex {
          margin-left: auto !important;

          @media (max-width: 527px) {
            display: flex;
            justify-content: center;
            margin: auto;
          }
        }
      }
    }

    .search_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      .search_box {
        width: 18%;

        @media (max-width: 900px) {
          width: 100% !important;
        }
      }

      .search_field {
        margin: 8px 0px 8px 4px;

        @media (max-width: 430px) {
          width: 100%;
          padding-right: 5px;
          margin-left: 4px;
        }
      }
    }
  }

  // @media (max-width: 1600px) {
  //   zoom: 1.33 !important;
  // }
  // @media (max-width: 1720px) and (min-width: 1600px) {
  //   zoom: 1 !important;
  // }
}
