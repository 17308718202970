#favourite-shipment {
  .row-dashbored {
    padding: 20px 0px;
  }

  padding: 0px 24px;

  @media (max-width: 1600px) {
    zoom: 1.3;
  }

  .noShipmentFound-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 19px;
    font-size: 30px;
    color: rgb(108, 117, 125);
  }

  .noShipmentFound-subTitle {
    display: flex;
    justify-content: center;
    margin-top: 9px;
    color: rgb(108, 117, 125);
  }
}
