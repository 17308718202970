#ActivityTracker {
  .title-date-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .activity-tracker-date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    justify-content: flex-end;

    &:nth-of-type(3) {
      width: 10rem !important;
    }
    .css-b62m3t-container {
      width: 20rem !important;
      @media (min-width: 425px) and (max-width: 767px) {
        width: 10rem !important;
      }
      @media (min-width: 768px) and (max-width: 970px) {
        width: 15rem !important;
      }
      z-index: 100 !important;
    }

    .ant-space-item > .ant-picker {
      top: 0px;
    }
  }

  .heading-text {
    font-size: 15px;
    color: gray;
    font-weight: 600;
    margin: 0px 0px 8px 4px;
    letter-spacing: 0.4px;
  }

  .activity-switch-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f4f4f5;
    border-radius: 6px;
    padding: 5px 5px;
    background-color: #f7f7f9;
    color: black !important;
    gap: 5px;

    .activity-type-btn {
      border: none;
      padding: 6px 16px;
      cursor: pointer;
      color: black !important;
      transition: all ease-in 0.2s;
      border-radius: 5px;

      .activity-btn-text {
        color: #086294 !important;

        &.active-text {
          color: white !important;
        }
      }

      &:hover {
        background-color: #f0f0f0;
      }

      &.active-btn {
        background-color: #086294;
      }
    }
  }
}
