.triangle-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  right: -6px;
  position: absolute;
  top: -11px;
  cursor: pointer;
}
