.LinkedInfo-container {
  padding: 20px 2% 20px 2%;
  margin: 0px 3% 20px 3%;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .linkedInfo-header-container {
    margin-top: 10px;
    background-color: #bfd6e3;
    padding: 20px 30px 20px 30px;
    border-radius: 5px;

    .LinkedInfo-header {
      padding: 1px 2px 1px 2px;
      margin-bottom: 20px;
      background-color: #005d91;
      border-radius: 10px;

      .company-name-header {
        font-size: 24px;
        color: white;
        text-align: center;
        margin-top: 27px;
      }

      .company-contact {
        color: white !important;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;

        &:hover {
          color: antiquewhite;
        }
        .company-social-media-pipe {
          border: 1px solid white;
          height: 15px;
          margin: 0px 2px 0px 2px;
        }
        .social-media-container {
          margin-left: 2px;
          display: flex;
          justify-content: space-between;

          .social-icons {
            color: white !important;
            margin-top: 3px;
            margin-right: 5px;
            &:hover {
              transition: 0.3s !important;
              color: rgba(245, 245, 245, 0.829) !important;
            }
          }
        }
      }
    }

    .company-detail-summary {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.842);
      gap: 30px;
    }
  }

  .company-keywords {
    margin-top: 3.1%;
    margin-bottom: 20px;

    .keywords {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;

      .keywords-name {
        background-color: #bfd6e3;
        color: rgb(22, 22, 22);
        margin: 5px;
        padding: 5px 10px;
        border-radius: 5px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  .company-details-linked-in {
    margin-top: 3.1%;
    margin-bottom: 20px;
    position: relative;

    .linkedIn-data-display {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      background-color: #d7e8f3;
      padding: 20px;
      border-radius: 5px;

      .column-for-company-data {
        flex: 1;
        margin: 0 20px;

        .company-details-data {
          margin: 20px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .logo-column {
        position: absolute;
        transform: translate(-50%, -19%);
        opacity: 0.4;
        left: 50%;
        top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          max-height: 100px;
        }
      }
    }
  }

  .company-detail-table {
    margin-top: 3.1%;
  }
}

.heading-blue {
  background-color: #005d91;
  color: white;
  letter-spacing: 2;
  padding: 10px;
  border-radius: 7px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  max-width: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.llmEmployeeErr {
  font-size: 18px;
  color: #545454;
  margin-left: 14px;
  margin-bottom: 14px;
}
