#monthTab_container {
    display: flex;
    align-items: center;
    background-color: #bfd6e3;
    border: 1px solid #bfd6e3;
    border-radius: 5px;
    height: 50px;
    padding: 0px 0.5%;

    .monthTab-div {
        display: flex;
        flex: 1;
        gap: 1%;

        .tab-div {
            min-width: 60px;

            .monthTab-btn {
                border: none;
                background-color: #bfd6e3;
                box-shadow: none;
                height: 40px;
                width: 100%;
                font-size: 16px;
                color: black;
            }

            .active {
                border: 1px solid #005d91;
                background: #005d91;
                color: white;
                height: 40px;
                width: 100%;
                font-size: 16px;
            }
        }

        .tab-div-dateRangePicker {
            position: relative;
            top: 5px;
        }
    }
}