.recent-search-country-card-container {
  width: 100%;
  //   min-height: 300px !important;
  padding-top: 20px !important;
  padding-left: 20px !important;
  .recent-search-card-box-country {
    background-color: #fff;
    border-radius: 10px !important;
    -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    padding: 11px 10px 11px 10px; // change
    height: 100%;
    .recent-search-card-heading {
      display: flex;
      align-items: center;
      .flag-countryName-container {
        display: flex;
        align-items: center;
        width: 45%;
        span {
          font-weight: 600 !important;
          margin-left: 8px !important;
          font-size: 14px !important;
        }
      }
      .view-button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        .view-button-recent-country {
          display: flex;
          align-items: center;
          font-size: 14px;
          background-color: #005d91;
          color: white;
          border-radius: 5px;
          border: none;
          width: 70px;
          justify-content: space-between;
        }
        &:hover {
          .view-button-recent-country {
            font-weight: bolder;
          }
        }
      }
      .ribbon-box-for-recent-country {
        position: relative;
      }
      .country-search-ribbon-success-trade-lable {
        width: 0;
        height: 0;
        border-right: 13px solid #1abc9c;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
        @media (max-width: 1600px) {
          border-bottom: 15px solid transparent;
        }
      }
      .square-for-lable {
        width: 0;
        height: 0;
        border-right: 10px solid #158e76;
        border-top: 10px solid transparent;
        transform: scale(-1);
        position: absolute;
        bottom: -33%;
        right: 0px;
      }
      .country-search-ribbon-trade-lable {
        display: inline-flex;
        position: relative;
        z-index: 1;
        right: -19px;
      }
      .rebbon-text-trade-lable-recent-country {
        background: rgb(26, 188, 156);
        background: linear-gradient(
          80deg,
          rgba(26, 188, 156, 1) 59%,
          rgba(217, 217, 217, 1) 100%
        );
        width: 75px;
        height: 26px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: white;
        font-weight: 500;
        font-size: 15px;
        line-height: 28px;
      }
      .country-search-ribbon-success-trade-lable-1 {
        width: 0;
        height: 0;
        border-right: 11px solid #158e76;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
        @media (max-width: 1600px) {
          border-right: 10.5px solid #158e76;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
        }
      }
      .country-search-ribbon-trade-lable-1 {
        display: flex;
        position: absolute;
        left: -5px;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
      }
      .rebbon-text-trade-lable-recent-country-none {
        background: #158e76;
        width: 80px;
        height: 22px;
      }
    }
  }
}
.rebbon-text-trade-lable-recent-country > font {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
