.ag-theme-alpine {
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-width: 2px;
  --ag-header-column-separator-color: #dfdfdf;
  --ag-header-column-font-size: 18px;
  --ag-header-background-color: rgb(194, 216, 229);
}

.ag-header-cell {
  font-size: 18px;
}
.ag-header-cell-text {
  color: #005d91;
}
.ag-tool-panel-wrapper {
  width: 350px !important;
  font-size: 14px;
}

.ag-header-cell-menu-button {
  display: none !important;
}

.ag-theme-alpine .ag-side-buttons,
.ag-theme-alpine-dark .ag-side-buttons {
  width: calc(var(--ag-grid-size) * 6) !important;
  font-size: 16px;
}

.ag-theme-alpine {
  // margin-right: -15px;
  height: calc(100vh - 19.2rem);
  @media (max-width: 1600px) {
    height: calc(100vh - 15.8rem);
  }
  // height: 67vh;

  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.ag-drag-handle {
  font-size: 17px !important;
}

.ag-theme-alpine .ag-row {
  font-size: calc(var(--ag-font-size) + 1.5px);
}

.ag-input-field-input {
  height: 30px;
}

.ag-column-select-virtual-list-viewport {
  padding: calc(var(--ag-widget-container-vertical-padding) * 0.9) 0px;
}

.ag-column-drop-horizontal {
  height: 50px;
  font-size: 14px;
  border-bottom: none;
}

.ag-column-drop-cell {
  height: calc(var(--ag-grid-size) * 4.9);
}

.ag-icon {
  font-size: 18px;
}

.ag-group-title-bar {
  margin: 9px 14px;
  background: #ededed;
  border-radius: 5px;
}

.ag-filter-toolpanel-group-level-0-header {
  height: calc(var(--ag-grid-size) * 7);
}

.ag-ltr .ag-filter-toolpanel-search {
  padding-left: 15px;
}

.ag-root-wrapper {
  border: none;
}

.ag-column-select-checkbox.ag-labeled.ag-label-align-right.ag-checkbox.ag-input-field {
  display: none;
}

// span.ag-column-select-column-label {
//   pointer-events: none;
// }

.ag-row-group[style*="pointer-events: none"] > [col-id="ag-Grid-AutoColumn"] > span > .ag-group-contracted {
  display: none;
}

.ag-row-odd {
  background-color: #ecf0f2;
}
.ag-theme-alpine .ag-standard-button {
  border-color: #005d91;
  color: #ffffff;
  background-color: #005d91;
  font-size: 15px;
}

button.ag-standard-button.ag-filter-apply-panel-button:hover {
  border-color: #024a73;
  background-color: #024a73;
}

// .ag-theme-alpine .ag-standard-button:disabled,
// .ag-theme-alpine-dark .ag-standard-button:disabled {
//   pointer-events: none;
// }

.ag-theme-alpine .ag-header {
  z-index: 0;
}
// .ag-column-drop-wrapper {
//   border-bottom: 2px solid #cdcaca;
// }

// .ag-row-group[style*="pointer-events: none"] > [col-id="ag-Grid-AutoColumn"] > span > .ag-group-child-count {
//   display: none;
// }

// .ag-row-group[style*="pointer-events: none"] > [col-id="ag-Grid-AutoColumn"] > span::after {
//   content: "(1)";
//   margin-left: 5px;
//   font-family: "Rubik";
// }

// .ag-theme-alpine .ag-root,
// .ag-theme-alpine .ag-row,
// .ag-theme-alpine .ag-cell {
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
// }

.ag-dnd-ghost {
  @media (max-width: 1600px) {
    zoom: 1.33 !important;
  }
}

// .ag-root-wrapper {
//   border-top: 1px #dadada solid;
// }

.ag-column-drop-wrapper {
  border-bottom: 1px #e4e4e4 solid;
}

// .css-2613qy-menu {
//   background-color: red !important;
//   z-index: 10000;
// }

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#myGrid {
  flex: 1;
}

.my-chart {
  flex: 1;
  margin-top: 0.5rem;
  border: 1px solid var(--ag-border-color);
}

#buttons {
  padding-bottom: 10px;
}
