.ant-table-cell::-webkit-scrollbar-thumb {
  // background: rgb(142 132 132 / 9%);
  background-color: #70707016;
  border-radius: 6px;
  border-left: 1px solid rgba(94, 94, 94, 0.308);
  border-right: 1px solid rgba(94, 94, 94, 0.308);
  height: 3px;
  box-shadow: inset 0 0 3px rgb(128 128 128 / 37%);

  &:hover {
    background-color: rgb(158, 165, 171);
  }
}

.table2 {
  user-select: none !important;
}

.table {
  user-select: none !important;
}

.table2 .ant-table-content {
  overflow: overlay hidden !important;
}

.table .ant-table-content {
  overflow: hidden !important;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  color: rgb(0 93 145) !important;
  background: #c2d8e5 !important;
  font-size: 16px !important;
  border-radius: 0px !important;
  height: 62px !important;
  white-space: wrap;
}

.ant-table-wrapper .ant-table-thead>tr>td {
  color: rgb(0 93 145) !important;
  background: #c2d8e5 !important;
  font-size: 16px !important;
  border-radius: 0px !important;
  height: 62px !important;
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 3px;
  background-color: #ffffff;
  height: 1.7em;
  border-radius: 50px !important;
}

.ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 3px;
  background-color: #ffffff;
  height: 1.7em;
}

.ant-table-wrapper .ant-table-tbody>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]) {
  border-right: 2px solid;
  border-image: radial-gradient(#000000, #00000000)0 1;
}

.table2 .ant-table-wrapper .ant-table-tbody>tr:not(:first-child)>td {
  height: 47px !important;
  white-space: nowrap;
  max-width: 420px !important;
  min-width: 120px !important;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 4px 16px;
  border-bottom: none;
}

#explore_antd_table .ant-table-wrapper .ant-table-tbody>tr>td:nth-child(2) {
  overflow: hidden;
}

.table .ant-table-wrapper tbody tr:nth-child(2) {
  display: none;
}

.table .ant-table-wrapper .ant-table-thead tr>td:nth-child(1) {
  border-radius: 8px 0px 0px 0px !important;
}

.table2 .ant-table-wrapper .ant-table-thead tr>td:nth-child(1) {
  border-radius: 8px 0px 0px 0px !important;
}

.table .ant-table-wrapper .ant-table-thead tr>th:nth-child(1) {
  border-radius: 8px 0px 0px 0px !important;
}

.table2 .ant-table-wrapper .ant-table-thead tr>th:nth-child(1) {
  border-radius: 8px 0px 0px 0px !important;
}

.table .ant-table-wrapper .ant-table-thead tr>th:nth-last-child(1) {
  border-radius: 0px 8px 0px 0px !important;
}

.table2 .ant-table-wrapper .ant-table-thead tr>th:nth-last-child(1) {
  border-radius: 0px 8px 0px 0px !important;
}

.table .ant-table-wrapper .ant-table-thead>tr>td {
  color: rgb(0 93 145) !important;
  background: #c2d8e5 !important;
  font-size: 16px !important;
  height: 62px !important;
}

.table2 .ant-table-wrapper .ant-table-tbody>tr:nth-child(odd)>td {
  background-color: #ecf0f2 !important;
}

.ant-table-wrapper .ant-pagination>li>a {
  color: #005d91 !important;
  padding: 0px 12px !important;
}

.ant-table-wrapper .ant-table>.ant-table-thead {
  line-height: 1 !important;
}

.ant-table-wrapper .ant-table {
  line-height: 1 !important;
}

.ant-table-wrapper .ant-table .ant-table-tbody {
  line-height: 0.750 !important;
}

.ant-table-cell {
  max-width: 420px !important;
}

.ant-table-wrapper .ant-table ::-webkit-scrollbar {
  position: absolute;
  width: 9px;
  height: 9px;
}

.ant-table-wrapper .ant-table .ant-table-tbody ::-webkit-scrollbar {
  position: absolute;
  width: 4px;
  height: 6px;
}

#pagination {
  border-bottom-width: 1px solid #dee2e6 !important;
}


.table2 .MuiPagination-root {
  position: relative;
  box-shadow: none !important;
  display: flex !important;
  justify-content: end !important;
  padding: 10px !important;
}

.table2 .Mui-selected {
  background-color: #ffffff !important;
  color: black;
  border: 1px solid #005d91 !important;
  border-radius: 5px;
}