* {
  font-family: "Rubik";
  box-sizing: border-box;
}
body > #webpack-dev-server-client-overlay {
  display: none !important;
}
body > #webpack-dev-server-client-overlay-div {
  display: none !important;
}
::-webkit-scrollbar {
  // display: block;
  position: absolute;
  width: 15px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(158, 165, 171);
  border-right: none;
  border-left: none;
  border-radius: 10px;
}

#goog-gt-tt {
  display: none !important;
  visibility: visible !important;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 8px;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

.ellipsisName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsisNameWithWidth {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
}
.ellipsisNameWithWidthMarketSummary {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

body {
  .highcharts-credits {
    display: none !important;
  }

  .paddingAllLeftRight {
    padding: 0px 1.2%;
  }

  .paddingAll {
    padding: 1.2%;
  }

  .tooltipTitle {
    font-size: 12px;
  }

  @media (max-width: 1600px) {
    zoom: 75%;

    .ag-theme-alpine {
      zoom: 1.33 !important;
    }

    .widget-visible > iframe {
      zoom: 1.34;
      bottom: 3px !important;
    }

    .tawk-min-container .tawk-button-circle.tawk-button-large {
      width: 53px !important;
      height: 53px !important;
    }

    .zoom_out_1_33 {
      zoom: 1.33 !important;
    }

    .zoom_in_75 {
      zoom: 75% !important;
    }
  }

  @media (max-width: 1720px) and (min-width: 1600px) {
    zoom: 94%;

    .widget-visible > iframe {
      zoom: 1.34;
    }
  }

  background-color: #ffffff;

  button {
    cursor: pointer !important;
  }

  .d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .d-flex-column {
    display: flex;
    flex-direction: column;
  }

  .button-color {
    color: #fff;
    width: 100%;
    height: 41px;
    background-color: #005d91;
    border-color: #005d91;
    font-size: 15px;
    border: none;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: #07527d !important;
      border-color: #07527d !important;
    }
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    color: #343a40;
  }

  .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;

    &:hover {
      color: #fff;
      background-color: #585f66 !important;
      border-color: #585f66 !important;
    }
  }

  .badge {
    font-family: "Rubik";
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 17px;
    font-weight: 700;
    margin-left: 5px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
  }

  #all-pages-title {
    display: flex;
  }

  .bg-soft-blue {
    background-color: #005d9140 !important;
  }

  .bg-soft-green {
    color: #1abc9c !important;
    background-color: rgba(26, 188, 156, 0.25);
  }

  .bg-soft-red {
    background-color: rgba(241, 85, 108, 0.25);
    color: #f1556c !important;
  }

  .set-interval {
    font-size: 12px;
    padding: 4px;
    border-radius: 5px;
    font-weight: 600;
  }

  .text-blue {
    color: #005d91 !important;
  }

  .success-submit {
    height: 364px;
    padding-top: 82px;
  }

  .background-content {
    background-image: url("./Asset/images/bg-pattern.png");
    background-color: #005d91;
    background-size: cover;
    background-position: center;
    height: 100vh;

    @media (max-width: 1600px) {
      height: 133.3vh;
    }
  }

  .text-center {
    text-align: center !important;
  }

  .img-center {
    justify-content: center;
    display: flex;
    margin: auto;
  }

  .logout-checkmark .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    -webkit-animation: dash 2s ease-in-out;
    animation: dash 2s ease-in-out;
  }

  .logout-checkmark {
    width: 100px;
    margin: 0 auto;
    padding: 20px 0;
  }

  .logout-checkmark .spin {
    -webkit-animation: spin 2s;
    animation: spin 2s;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  label {
    font-weight: 600;
    line-height: 1.5;
    color: #6c757d;
    text-align: left;
    font-size: 15px;
  }

  .text-muted {
    color: #98a6ad !important;
  }

  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  ::placeholder {
    color: #adb5bd;
    opacity: 1;
  }

  .error-message {
    color: #f1556c;
    margin-top: 5px;
    margin-bottom: 6px;
    padding-left: 3px;
    font-size: 14px;
  }

  .error-message::before {
    content: "\F159";
    font-family: "Rubik" !important;
    margin-right: 4px;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  a {
    color: #adb5bd !important;
    text-decoration: none;
  }

  .sm-anchor {
    color: #6c757d !important;
  }

  .sm-modal-anchor {
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    text-align: center;
  }

  .ribbon-box .ribbon {
    position: relative;
    clear: both;
    padding: 5px 12px;
    margin-bottom: 15px;
    -webkit-box-shadow: 2px 5px 10px rgba(50, 58, 70, 0.15);
    box-shadow: 2px 5px 10px rgba(50, 58, 70, 0.15);
    color: #fff;
    font-size: 13px;
    font-weight: 600;
  }

  .ribbon-box .ribbon:before {
    content: " ";
    border-style: solid;
    border-width: 10px;
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    margin-bottom: -10px;
    z-index: -1;
  }

  .ribbon-box .ribbon.float-right {
    margin-right: -22px;
    border-radius: 3px 0 0 3px;
  }

  .ribbon-box .ribbon.float-right:before {
    right: 0;
  }

  #btn-add {
    color: #fff;
    background-color: #005d91;
    border-color: #005d91;
  }

  #btn-delete {
    background: #df0000c7;
    color: white;
  }

  #btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
}

.x-icon {
  color: black !important;
}

.ag-select-list {
  top: calc(100% - 119px) !important;
  left: unset !important;
  right: 400px !important;
}

.trade-panel-loader {
  position: absolute;
  top: 46%;
  left: 46%;
}

.ag-select-list {
  top: calc(100% - 119px) !important;
  left: unset !important;
  right: 400px !important;
}
