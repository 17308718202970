#Modal {
  .modal-styles {
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: "Rubik" !important;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24;
    outline: none;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;

    // height: 80%;
    // overflow: auto;
    @media (max-width: 700px) {
      width: 92% !important;
    }

    .header-icon {
      display: flex;
      margin-right: 10px;
      font-size: 27px;
      color: #005d91;
    }
  }

  .d-flex {
    display: flex;
    align-items: center;
  }

  .modalInsideTitle {
    background-color: #c2d8e5;
    padding: 10px;
    margin-top: 25px;
  }

  .m_10 {
    font-family: "Rubik" !important;
    margin: 10px 0px !important;
  }

  .main {
    font-family: "Rubik" !important;
    padding: 10px 19px;
    background-color: #ffffff;
    border-bottom: 2px solid #e0e3e7;
    // position: sticky;
    // top : 0px;
  }

  .headerText {
    font-family: "Rubik";
    margin-right: auto;
    color: #005d91;
    font-weight: 800;
  }

  .modalBody {
    font-family: "Rubik" !important;
    padding: 15px 15px 15px 15px !important;
  }

  .next-prev {
    padding: 15px 15px;
    display: inline-block;
  }

  .closeButton {
    font-family: "Rubik";
    // background-color: #6c757d;
    color: #fff;
    background-color: #f1556c;
    border-color: #f1556c;

    &:hover {
      color: #fff;
      background-color: #ee324d;
      border-color: #ed2643;
    }
  }

  .print {
    margin-left: 10px;
  }

  .summary {
    margin-left: 10px;
  }

  .hr {
    font-family: "Rubik";
    border: 1px solid #dee2e6;
  }

  // .footer-modal{
  //   position: sticky;
  //   bottom: 0;
  //   background-color: white;
  // }
  .footer-modal-content {
    font-family: "Rubik";
    padding: 15px 15px;
    float: right;
  }

  .close {
    cursor: pointer;
    font-family: "Rubik";
    float: right;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: none;
  }
}
