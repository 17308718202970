.main-container-summary {
  display: flex;
  flex-direction: column;
  border: 1px solid #005d91;
  border-radius: 5px;
  align-items: center;
  width: 7.5rem;
  justify-content: center;
  color: #005d91;
  margin: 0px 5px;
  font-size: 0.9rem;
  height: 63px;

  @media screen and (max-width: 1096px) {
    width: 100%;
  }
}

.box-top {
  display: flex;
}

.box-down {
  margin-top: 4px;
}

.line {
  height: 1px;
  background: linear-gradient(
    96deg,
    rgba(255, 255, 255, 1) 1%,
    rgba(0, 93, 145, 1) 50%,
    rgba(255, 255, 255, 1) 99%
  ) !important;
  width: 60%;
}

.summary-container {
  display: flex;
  width: 100%;
  gap: 1%;

  .main-container-workSpace {
    display: flex;
    flex-direction: row;
    border: 1px solid #005d91;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    color: #005d91;
    font-size: 1rem;
    width: 100%;
    height: 45px;
    padding: 0px 10px;
  }

  .box-top-workSpace {
    display: flex;
  }

  .box-down-workSpace {
    display: flex;
    flex-direction: row;
  }

  .line-workSpace {
    margin-right: 10px;
    // height: 50%;
    background: linear-gradient(
      96deg,
      rgba(255, 255, 255, 1) 1%,
      rgba(0, 93, 145, 1) 50%,
      rgba(255, 255, 255, 1) 99%
    ) !important;
    width: 2px;
  }
}
