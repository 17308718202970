.payload-container {
  height: 600px;
  overflow-y: scroll;
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f4f7f9;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: auto;
  overflow-y: auto;

  .section {
    margin-bottom: 30px;
    padding: 10px 12px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;

    h3 {
      color: #005d91;
      width: 100%;
    }

    .section-item {
      flex: 1 1 calc(50% - 10px);
      min-width: 300px;
      padding: 10px;
      background-color: #f9fafc;
      border-radius: 5px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);

      p {
        font-size: 1.1em;
        color: #333;
        line-height: 1.6;
        margin: 8px 0;

        strong {
          font-weight: 600;
          color: #005d91;
        }
      }
    }
  }

  .criteria {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    .criteria-item {
      flex: 1 1 calc(50% - 10px);
      min-width: 300px;
      padding: 15px;
      background-color: #e9f0f7;
      border-radius: 5px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
      margin-bottom: 15px;

      p {
        margin: 5px 0;
        color: #555;
        font-size: 1.1em;

        strong {
          color: #005d91;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .payload-container {
    padding: 15px;
  }

  .section {
    flex-direction: column;
  }

  .section-item {
    flex: 1 1 100%;
  }

  .criteria .criteria-item {
    flex: 1 1 100%;
  }
}
