$powerBi-component-height: 870px;

.table-container {
  width: 100%;
  position: relative;
  height: calc(100vh - 181px - 198px) !important;
  overflow-y: scroll;

  @media (min-width: 1200px) and (max-width: 1599px) {
    height: calc(100vh - 112px) !important;
  }

  @media (min-width: 769px) and (max-width: 1199px) {
    height: calc(100vh - 215px) !important;
  }

  .loader-dashboard-table {
    position: absolute;
    top: 46%;
    left: 46%;
  }

  .fav-company-analysis-powerBi {
    // width: 99.5%;
    // height: 500px;
    // background-color: #e5eef4;
    // margin-top: 2%;
  }

  &::-webkit-scrollbar {
    position: absolute;
    width: 6px;
    height: 5px;
  }
}

.height-100vh {
  height: calc(100vh - 255px);
}

.width100 {
  width: 100%;
}

.bg-primary {
  background-color: #e5eef4 !important;
}
.save-query-grid {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr) !important;
  gap: 40px !important;
  overflow-y: scroll;
  padding: 0.6%;

  // @media (min-width: 1200px) and (max-width: 1599px) {
  //   height: calc(100vh - 115px) !important;
  // }

  @media (min-width: 769px) and (max-width: 1199px) {
    // height: calc(100vh - 215px) !important;
    grid-template-columns: repeat(3, 1fr) !important;
  }

  @media (min-width: 769px) and (max-width: 1439px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  @media (min-width: 860px) and (max-width: 1135px) {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  @media (min-width: 0px) and (max-width: 859px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .info-card {
    max-width: 100% !important;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
}

.dashboard-container.sidebar-collapsed .save-query-grid {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
}

.recent-country-search {
  width: 100%;
  height: 100%;
  .table-border-body {
    height: 100% !important;
    padding: 114px !important;
    border-radius: 5px !important;
  }
  .recent-search-card-container {
    display: flex;
    flex-direction: column;
    @media (min-width: 0px) and (max-width: 425px) {
      .view-button-container {
        display: none;
      }
    }
    @media (min-width: 425px) and (max-width: 1024px) {
      .flag-countryName-container {
        width: 45%;
      }
    }
  }
}
.help-index-img {
  background-image: url("../../../../Asset/images/videobackground.png");
  background-size: cover;
  padding: 4% 1%;
  margin-bottom: 25px;
  border-radius: 6px;
  width: 100%;
  height: calc(100vh - 138px) !important;

  @media (min-width: 769px) and (max-width: 1199px) {
    height: calc(100vh - 240px) !important;
  }

  @media (min-width: 1600px) and (max-width: 2560px) {
    height: calc(100vh - 415px) !important;
  }
}
