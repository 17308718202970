#password-recovery {
  .recovery-col {
    padding-top: 63px;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .pt-24 {
    padding-top: 24px;
  }

  label {
    font-size: 14px;
  }

  .font-14 {
    font-size: 14px !important;
    line-height: 1.5;
  }

  .text-width {
    width: 379px;
    @media (max-width: 525px) {
      width: auto;
    }
  }

  .success-recovery {
    .success-pass-submit {
      height: 346px;
      padding-top: 82px;
    }

    .success-text {
      margin: auto;
      padding: 0px 11px;
    }
  }
  .font-success {
    font-size: 1.5rem;
    color: #343a40;
  }

  .error-page {
    height: 370px;
    .error-text {
      margin: auto;
      padding: 0px 11px;
    }
  }

  .card-recovery {
    width: 451px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    margin-bottom: 24px;
    -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
    box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
    height: auto;
    border: 0 solid #f7f7f7;
    border-radius: 0.25rem;

    @media (max-width: 524px) {
      width: 88%;
    }
  }

  .f-14 {
    font-size: 14px;
  }

  .error-return {
    display: flex;
    justify-content: center;
  }
  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .text-white {
    color: #fff !important;
    // font-size: 16px;
    font-weight: 600;
    margin: auto 5px;
  }

  .logout-checkmark .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    -webkit-animation: dash 2s ease-in-out;
    animation: dash 2s ease-in-out;
  }
  .logout-checkmark {
    width: 100px;
    margin: 0 auto;
    padding: 20px 0;
  }

  .logout-checkmark .spin {
    -webkit-animation: spin 2s;
    animation: spin 2s;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  .go-login-btn {
    background-color: #005d91;
    border-color: #005d91;
    border-color: #005d91;
    padding: 10px 20px !important;
    border-radius: 3px;
    font-size: 15px;
    border: none;
    color: white;
    margin-top: 20px;
    padding: 5px;
    &:hover {
      color: #fff;
      background-color: #07527d !important;
      border-color: #07527d !important;
    }
  }
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes text {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes text {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .p-31 {
    padding: 31px !important;
  }
}
