.ant-picker-clear {
  display: none;
}

.ant-picker-presets>ul>li {
  margin: 10px 0px;
}

.ant-picker-presets>ul>li:first-child {
  margin: 50% 0px 10px 0px;
}

.ant-space-item>.ant-picker {
  height: 40px !important;
  top: -5px;
}

.ant-picker-cell-disabled>.ant-picker-cell-inner {
  text-decoration: line-through !important;
}

.ant-picker-cell-selected>.ant-picker-cell-inner {
  background: #005d91 !important;
}

.ant-picker-cell-range-end>.ant-picker-cell-inner {
  background: #005d91 !important;
}

.ant-picker-presets li:nth-child(1) {
  background: #005d91;
  color: white;
}

.ant-picker-presets li {
  &:hover {
    background-color: #005d91 !important;
    color: #ffffff !important;
    transition: 0.3s;
  }
}


.ant-picker-dropdown .ant-picker-year-panel .ant-picker-content td {
  color: rgba(0, 0, 0, 0.88) !important
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  border: none !important;
}