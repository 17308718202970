#detailsComponent {
  // position: sticky;
  // top: 0px;
  // z-index: 2;
  // background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .workspace-page-title {
    display: flex;
    margin: auto;

    @media (max-width: 1000px) {
      margin: 10px;
      margin-bottom: 30px;
    }

    .sub-title {
      font-size: 1.2rem !important;
      padding-right: 3px;
    }

    .font-style {
      font-size: 1.4rem;
      position: relative;
      bottom: 1px;
      left: 4px;
    }
  }

  #details-container {
    padding: 10px 0px;
    background: white;
    display: flex;
    align-items: center;

    #filter-title {
      margin-right: 15px;
      color: #005d91;
    }

    .line {
      height: 30px;
      background: linear-gradient(
        265deg,
        rgb(255, 255, 255) 1%,
        rgb(0, 93, 145) 50%,
        rgb(255, 255, 255) 99%
      ) !important;
      width: 1px;
      margin-right: 15px;
    }

    .details {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }
}

@media (max-width: 770px) {
  #detailsComponent {
    display: block;
  }
}
