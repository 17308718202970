.dashboard-container {
  display: flex;
  height: calc(100vh - 358px) !important;

  @media (min-width: 769px) and (max-width: 1199px) {
    height: calc(100vh - 210px) !important;
  }

  .sidebar {
    width: 266px !important;
    background-color: #e5eef4 !important;
    color: #6c757d !important;
    display: flex !important;
    flex-direction: column !important;
    transition: width 0.3s ease !important;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
    height: calc(100vh - 181px - 198px) !important;
    overflow: hidden !important;

    @media (min-width: 1200px) and (max-width: 1599px) {
      height: calc(100vh - 112px) !important;
    }

    @media (min-width: 769px) and (max-width: 1199px) {
      height: calc(100vh - 214px) !important;
    }

    &.collapsed {
      width: 60px !important;

      .tab-item {
        padding: 0 !important;
        justify-content: center !important;
        text-align: center !important;

        .MuiSvgIcon-root {
          margin-right: 0 !important;
        }

        .tab-text {
          display: none !important;
        }
      }
    }

    .toggle-btn {
      background: #005d91 !important;
      color: #ffffff !important;
      font-size: 15px !important;
      cursor: pointer !important;
      margin: 10px !important;
      padding: 5px !important;
      border-radius: 5px !important;
      transition: background-color 0.3s ease !important;

      &:hover {
        background-color: #004d7a !important;
      }
    }

    .tab-list {
      list-style: none !important;
      padding: 0 !important;
      margin: 0 !important;
      margin-top: 10px !important;

      .tab-item {
        display: flex !important;
        align-items: center !important;
        padding: 15px 15px !important;
        cursor: pointer !important;
        font-size: 12px !important;
        border-left: 5px solid transparent !important;
        transition: background-color 0.3s ease, border-color 0.3s ease !important;
        position: relative !important;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1) !important;

        .MuiSvgIcon-root {
          font-size: 24px !important;
          margin-right: 7px !important;
        }

        .tab-text {
          flex: 1 !important;
        }

        &:hover {
          background-color: #dce5ea !important;
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15) !important;
        }

        &.active {
          background-color: #ffffff !important;
          color: #005d91 !important;
          border-left: 5px solid #005d91 !important;
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15) !important;
        }
      }
    }
  }

  .sidebar-table-container {
    flex: 1 !important;
    width: 80% !important;
    margin-left: 35px !important;
  }
}

@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;

    .sidebar {
      width: 100% !important;
      height: auto !important;

      &.collapsed {
        width: 100% !important;
      }
    }

    .sidebar-table-container {
      width: 70% !important;
    }
  }
}
