.switch-ie {
  position: relative;
  display: inline-block;
  width: 125px;
  height: 37px;
  margin-top: 10px;
  margin-left: auto;

  @media (max-width: 457px) {
    margin-left: 0px;
  }

  @media (max-width: 360px) {
    width: 110px;
  }

  @media (max-width: 600px) and (min-width: 457px) {
    width: 162px;
  }
}

.switch-ie input {
  display: none;
}

.sliderBtn {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #005d91;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderBtn:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 5px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliderBtn {
  background-color: #005d91;
}

input:focus + .sliderBtn {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .sliderBtn:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(88px);
}

.on {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 52%;
  font-size: 12px;
  font-family: Verdana, sans-serif !important;

  @media (max-width: 360px) {
    left: 56%;
  }
}

.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 12px;
  font-family: Verdana, sans-serif !important;

  @media (max-width: 360px) {
    left: 56%;
  }
}

.sliderBtn.round {
  border-radius: 34px;
}

.sliderBtn.round:before {
  border-radius: 50%;
}
