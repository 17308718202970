.tooltip-for-mirrorData-selected-country-modal-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  width: 402px;

  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.show {
    opacity: 1;
    transform: translateY(1px);
  }

  .tooltip-container {
    max-height: 35vh;
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    overflow-y: scroll !important;

    &::-webkit-scrollbar {
      width: 5px !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(158 165 171 / 46%) !important;
    }

    .selected-country-span {
      display: flex;
      align-items: center;
      height: 20px;
      width: 106px;
      margin: 10px;

      .selected-country-flag {
        width: 30px;
        margin-right: 1px;
        border-radius: 3px;
      }

      .country-name {
        font-size: 12px;
        font-weight: 400;
        color: #6c757d;
        margin-left: 6px;
      }
    }
  }
}
