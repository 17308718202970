.card-box-workspace {
  padding: 15px !important;
  background-color: white;
  padding: 0.5rem;
  -webkit-box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
  border-radius: 0.25rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
  .workSpace-title-date-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .workSpace-title-container {
      display: flex;
      margin: 0px 10px 10px 0px;
      width: 60%;
      #workspace-name {
        cursor: pointer;
        margin-left: 0.7rem;
        color: #323a46 !important;
        font-size: 1.044em !important;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .set-image {
        width: 2.75rem;
      }
    }
    #create-date {
      margin: 0px 0px 10px;
      width: 41%;
      text-align: end;
      #set-date-text {
        color: rgb(108, 117, 125);
      }
      #set-cal-icon {
        position: relative;
        top: 3px;
        margin-right: 4px;
      }
    }
  }

  .workspace-highlight-content {
    border-radius: 4px;
    background-color: #ffffff;
    padding: 8px;
    align-items: center;
    display: flex;
    margin-top: 5px;
    .m-0 {
      margin: 0px !important;
      padding-top: 10px;
    }
    .m-5 {
      margin: 10px !important;
      text-transform: uppercase;
    }
    .workspace-text-align {
      text-align: center;
      letter-spacing: 0.9px;
    }
    .workspace-text-weight {
      font-weight: 500;
    }
    .workSpace-pipe {
      height: 50px;
      background: linear-gradient(
        96deg,
        rgba(255, 255, 255, 1) 10%,
        rgba(0, 93, 145, 1) 50%,
        rgba(255, 255, 255, 1) 90%
      );
      width: 2px;
    }
  }
  .m-auto {
    margin: auto;
    text-align: center;
  }
  .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    color: rgba(0, 0, 0, 0.2);
  }
  .workspaces-btn-container {
    margin-top: 28px;
    text-align: center;

    .workspaces-btn-grp {
      margin-left: 0px !important;
      width: 100%;
      justify-content: space-between;
    }
  }
}
