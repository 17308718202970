.company-details {
  padding: 20px;
  background-color: #ffffff;
  max-width: 900px;
  margin: 0 auto;

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }

  .detail-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 8px 0;
    border-bottom: 1px solid #ccc;

    &:nth-last-child(-n + 2) {
      border-bottom: none;
    }

    .detail-key {
      font-weight: bold;
      color: #005d91;
      margin-right: 10px;
      min-width: 150px;
    }

    .detail-value {
      color: #333;
      flex-grow: 1;
    }
  }
}

.error-message {
  text-align: center;
  padding: 10px;
  color: #cc0000af;
  font-size: 14px;
  font-weight: bold;
  margin: 10px auto;
}
