#auth-footer{
  .footer-alt {
    // left: 0 !important;
    text-align: center;
    background-color: transparent;
    padding-bottom: 20px;
  }
  .footer {
    width: 100%;
    margin: auto;
    bottom: 0;
    color: #98a6ad;
    left: 0;
  
    span {
      font-size: 14px;
    }
  }
}


