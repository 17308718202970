#modal-inner {
  background: white;
  position: absolute;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  height: 308px;
  overflow-y: scroll;
}

#modal {
  .view-more-modal {
    max-width: 1250px !important;
    min-width: 1250px !important;
  }
}
