.headerTitle {
  font-family: "Rubik" !important;
  margin-right: auto !important;
  color: #4c97e3 !important;
  font-weight: 800 !important;
  position: relative !important;
  overflow: hidden !important;

  // Add gradient effect
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.8),
      transparent
    );
    transform: skewX(-25deg);
    animation: shine 2.5s infinite;
  }
}

// Define animation
@keyframes shine {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.close-icon {
  cursor: pointer;
  font-family: "Rubik";
  float: right;
  font-size: 1.4rem;
  font-weight: 700 !important;
  line-height: 1;
  color: #000;
  text-shadow: none;
}
