.api-key-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .api-card {
    // background: #ffffff;
    // border-radius: 8px;
    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #004d7a;
      margin-bottom: 20px;
      h1 {
        font-size: 24px;
        text-align: left;
        color: #004d7a;
      }
    }

    .key-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .key-row {
        display: flex;
        align-items: center;

        label {
          font-weight: bold;
          margin-bottom: 5px;
          font-size: 16px;
          color: #6490ad;
          width: 15%;
        }

        .private-key-input {
          font-family: "Courier New", Courier, monospace;
          background: #f0f0f0;
          padding: 10px 15px;
          border-radius: 4px;
          width: 85%;
          text-align: center;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .btn-container {
            display: flex;
            flex-direction: row;
          }
        }

        .private-key {
          width: 86%;
          overflow-wrap: break-word;
          font-family: "Courier New", Courier, monospace;
          background: #f0f0f0;
          text-align: left;
        }
        .access-key {
          width: 93%;
          overflow-wrap: break-word;
          font-family: "Courier New", Courier, monospace;
          background: #f0f0f0;
          text-align: left;
        }
        .toggle-visibility-btn,
        .action-btn {
          border: none;
          cursor: pointer;
          font-size: 20px;
          // width: 7%;
          align-self: center;
          display: flex;
          &:hover {
            color: #007bff;
          }
        }
      }
    }
  }
}
