#dashBoardComponent {
  .row-dashbored {
    display: flex;
    align-items: center;
    margin-bottom: -20px;
  }
  #savedQueries {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: "Rubik" !important;
    color: black !important;
  }

  #demo-1 {
    border-radius: 6px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
