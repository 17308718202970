#marketCompany {
    .monthRange-container {
        padding: 0.5% 0px;
    }

    .marketCompany-filter {
        .filter-label-off-marketCompany {
            width: 4%;
            padding: 0px 0px 0px 10px;
            position: sticky;
            top: 65px;
            left: 1px;
            z-index: 100;

            #set-filters-showing-arrow-marketCompany {
                cursor: pointer;
                padding: 0px 4px;
                height: 42px;
                top: 27px;
                left: 4px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                position: absolute;
                background: #005d91;
                color: white;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
                width: 115px;
            }
        }

    }
}