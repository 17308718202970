#global-search {
    .monthRange-container {
        padding: 0.5% 0px;
    }

    .globalSearch-filter {
        .filter-label-off-globalSearch {
            width: 4%;
            padding: 0px 0px 0px 10px;
            position: sticky;
            top: 65px;
            left: 1px;
            z-index: 100;

            #set-filters-showing-arrow-globalSearch {
                cursor: pointer;
                padding: 0px 4px;
                height: 42px;
                top: 27px;
                left: 4px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                position: absolute;
                background: #005d91;
                color: white;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
                width: 115px;
            }
        }

        .globalSearch-country-label {
            font-size: 25px;
            display: block;
            margin: 0px 15px 15px 0px;
            color: #005d91;
            background-color: #c2d8e5;
            padding: 16px;
            position: sticky;
            top: 0px;
            border-radius: 5px;
            z-index: 3;
        }

        .globalSearch-data-container {
            height: 600px;
            width: 100%;
            overflow-Y: auto;
            background-color: white;

            @media (max-width:1600px) {
                height: 90vh;
            }
        }
    }
}