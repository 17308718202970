#chart-Table {
  .chart-table-header {
    background-color: #c2d8e5;
    color: #005d91;
  }
  .chart-table-header-1 {
    background-color: #c2d8e5;
    color: #005d91;
  }
  //   .chart-table-header::before {
  //     content: "";
  //     position: absolute;
  //     background-color: #ffffff;
  //     width: 3px;
  //     height: 35px;
  //     margin: -3px -18px;
  //     border-radius: 50px;
  //   }
  //   .chart-row::before {
  //     content: "";
  //     position: absolute;
  //     background-color: #7a7a7a;
  //     width: 2px;
  //     height: 25px;
  //     margin: 0px -18px;
  //   }
}
// ::-webkit-scrollbar {
//   position: absolute;
//   width: 5px !important;
//   height: 5px !important;
// }
