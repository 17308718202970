.select-countryCode-modal-body {
  display: flex;
  flex-direction: column;
  .grouped-radio-dropdown {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .container {
      display: flex;

      .region-dropdown {
        .MuiFormControl-root {
          .MuiInputBase-root {
            width: 200px;
            height: 35px;
          }
        }
      }
    }
  }

  .all-country {
    // border-radius: 8px;
    color: aliceblue;
    margin-top: 10px;
  }
}
