.select {
  ::-webkit-scrollbar {
    // display: block;
    position: absolute;
    width: 10px !important;
    height: 5px !important;
  }
  position: relative;
  background-color: #fff;

  select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.9rem + 2px);
    padding: 0.45rem 1.9rem 0.45rem 0.9rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px;

    &:focus {
      border-color: #b1bbc4;
      outline: 0;
    }

    option {
      font-size: 16px;
    }
  }

  .select-border-redius {
    border-radius: 6px !important;
  }
}
