// .switch {
//   position: relative;
//   display: inline-block;
//   width: 125px;
//   height: 37px;
//   margin-top: 10px;
//   margin-left: auto;

//   @media (max-width: 457px) {
//     margin-left: 0px;
//   }

//   @media (max-width: 360px) {
//     width: 110px;
//   }

//   @media (max-width: 600px) and (min-width: 457px) {
//     width: 162px;
//   }
// }

// .switch input {
//   display: none;
// }

// .sliderBtn {
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: #005d91;
//   -webkit-transition: 0.4s;
//   transition: 0.4s;
// }

// .sliderBtn:before {
//   position: absolute;
//   content: "";
//   height: 28px;
//   width: 28px;
//   left: 5px;
//   bottom: 5px;
//   background-color: white;
//   -webkit-transition: 0.4s;
//   transition: 0.4s;
// }

// input:checked + .sliderBtn {
//   background-color: #005d91;
// }

// input:focus + .sliderBtn {
//   box-shadow: 0 0 1px #2196f3;
// }

// input:checked + .sliderBtn:before {
//   -webkit-transform: translateX(55px);
//   -ms-transform: translateX(55px);
//   transform: translateX(88px);
// }

// .on {
//   color: white;
//   position: absolute;
//   transform: translate(-50%, -50%);
//   top: 50%;
//   left: 52%;
//   font-size: 12px;
//   font-family: Verdana, sans-serif !important;

//   @media (max-width: 360px) {
//     left: 56%;
//   }
// }

// .off {
//   color: white;
//   position: absolute;
//   transform: translate(-50%, -50%);
//   top: 50%;
//   left: 50%;
//   font-size: 12px;
//   font-family: Verdana, sans-serif !important;

//   @media (max-width: 360px) {
//     left: 56%;
//   }
// }

// .sliderBtn.round {
//   border-radius: 34px;
// }

// .sliderBtn.round:before {
//   border-radius: 50%;
// }

input[type="radio"] {
  display: none;
}

label {
  color: white;
  z-index: 10;
  cursor: pointer;
}

h4 {
  margin: 0;
  font-weight: 500;
}

.black-font {
  color: black !important;
}

.main-container {
  display: inline-block;
  vertical-align: middle;
  width: 250px;
  height: 41px;
  border-radius: 100px;
  background-color: white;
  position: relative;
  top: 0px;
  right: 0px;
  border: 1px solid #005d91;

  // @media screen and (max-width: 1600px) {
  //   right: 62px;
  // }
  // @media screen and (min-width: 1200px) and (max-width: 1599px) {
  //   right: 47px;
  // }
  // @media screen and (min-width: 900px) and (max-width: 1199px) {
  //   right: 47px;
  // }
  // @media screen and (min-width: 750px) and (max-width: 899px) {
  //   right: 27px;
  // }
  // @media screen and (min-width: 701px) and (max-width: 749px) {
  //   right: 9px;
  // }
  // @media screen and (min-width: 600px) and (max-width: 700px) {
  //   right: 9px;
  // }
  // @media screen and (min-width: 375px) and (max-width: 599px) {
  //   right: 0px;
  //   top: 20px;
  // }
  @media screen and (min-width: 320px) and (max-width: 389px) {
    margin: 10px 0px 5px 0px;
  }
}

.switch {
  height: 33px;
  width: 80px;
  background-color: #005d91;
  border-radius: 100px;
  position: absolute;
  left: 3px;
  top: 3px;
  transition: ease-in;
}

/*---------------- LEFT ------------------- */
.switch.left-to-center {
  animation-name: leftToCenter;
  animation-duration: 0.5s;
}

.switch.left-to-right {
  animation-name: leftToRight;
  animation-duration: 0.5s;
}

.switch.left-position {
  left: 3px;
}

.left-label {
  position: absolute;
}

#left + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 80px;
  border-radius: 100px;
}

#left:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 80px;
  border-radius: 100px;
}

@keyframes leftToCenter {
  from {
    left: 3px;
  }
  to {
    left: 85px;
  }
}

@keyframes leftToRight {
  from {
    left: 3px;
  }
  to {
    left: 167px;
  }
}

/* -------------------- center ------------------ */
.switch.center-to-left {
  animation-name: centerToLeft;
  animation-duration: 0.5s;
}

.switch.center-to-right {
  animation-name: centerToRight;
  animation-duration: 0.5s;
}

.switch.center-position {
  left: 85px;
}

.center-label {
  position: absolute;
  left: 85px;
}

#center + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 80px;
  border-radius: 100px;
}

#center:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 80px;
  border-radius: 100px;
}

@keyframes centerToLeft {
  from {
    left: 85px;
  }
  to {
    left: 3px;
  }
}

@keyframes centerToRight {
  from {
    left: 85px;
  }
  to {
    right: 1px;
  }
}

/* ------------------- RIGHT ------------------- */
.switch.right-to-left {
  animation-name: rightToLeft;
  animation-duration: 0.5s;
}

.switch.right-to-center {
  animation-name: rightToCenter;
  animation-duration: 0.5s;
}

.switch.right-position {
  left: 167px;
}

.right-label {
  position: absolute;
  right: 2px;
}

#right + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 80px;
  border-radius: 100px;
}

#right:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 80px;
  border-radius: 100px;
}

@keyframes rightToLeft {
  from {
    left: 167px;
  }
  to {
    left: 3px;
  }
}

@keyframes rightToCenter {
  from {
    left: 167px;
  }
  to {
    right: 85px;
  }
}
.country_type {
  font-size: 13px !important;
  color: #005d91;
}
.is_active {
  color: white;
}
