#successLoader-box {
  box-sizing: content-box;


  @media (max-width: 575px) {
    // width: 57% !important;
    padding: 24px 52px !important;
    word-break: break-word;
  }

  .loading-spinner {
    width: 3em;
    height: 3em;
    border: 0.3em solid;
    border-color: rgb(255, 255, 255) transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1.5s linear infinite;
    padding: 17px;

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}


.bg-block {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000052;
  width: 100% !important;
  height: 100%;
}

.bg-hide {
  background-color: white;
}