.buttonComponent {
  border-color: #005d91 !important;
  color: #005d91 !important;
  height: 40px;

  &:hover {
    background-color: #005d91 !important;
    border-color: #005d91;
    color: #ffffff !important;
    transition: 0.3s;
  }

  .MuiButton-startIcon {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .btn_typography {
    margin-bottom: 0px !important;
    font-size: 0.875rem;
    letter-spacing: 0.02857em !important;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.color-white {
  color: #ffffff !important;
  background-color: #005d91 !important;

  &:hover {
    background-color: #ffffff !important;
    color: #005d91 !important;
    transition: 0.3s;
  }
}

.typo_btn_font {
  font-size: 12px !important;
}

.deleteButton {
  border-color: #e63838 !important;
  color: #e63838 !important;
  height: 40px;

  &:hover {
    background-color: #e63838 !important;
    border-color: #e63838;
    color: #ffffff !important;
    transition: 0.3s;
    font-weight: 550 !important;
  }

  .MuiButton-startIcon {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.btnGreen {
  border-color: #1abc9c !important;
  color: #1abc9c !important;
  height: 40px;

  &:hover {
    background-color: #1abc9c !important;
    border-color: #1abc9c;
    color: #ffffff !important;
    transition: 0.3s;
  }
}

.btn-background-green {
  border-color: #1abc9c !important;
  background-color: #1abc9c !important;
  color: #ffffff !important;
  height: 40px;

  .MuiButton-startIcon {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  &:hover {
    background-color: #18a98c !important;
    border-color: #18a98c !important;

    color: #ffffff !important;
    transition: 0.3s;
  }
}

.btn-border {
  border-width: 0.5px 3px 3px 0.5px !important;
  border-style: solid !important;
  border-radius: 6px !important;
}

.btn-text {
  align-items: center;
  text-transform: uppercase;
  font-weight: 400 !important;
}

.MuiButton-startIcon {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.MuiLoadingButton-loadingIndicator {
  left: unset !important;
  text-align: center !important;
}

.btn-Apply-Filter {
  border-color: #006cab !important;
  background-color: #006cab !important;
  color: #ffff !important;
  height: 40px;

  .MuiButton-startIcon {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  &:hover {
    border-color: #a3cbe4 !important;
    background-color: #a3cbe4 !important;

    color: black !important;
    transition: 0.3s;
  }
}

.grayButton {
  border-color: #898c8d !important;
  color: #898c8d !important;
  height: 40px;

  &:hover {
    background-color: #9ea5ab !important;
    border-color: #9ea5ab;
    color: #ffffff !important;
    transition: all 0.3s;
  }

  .MuiButton-startIcon {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
