#summary-trade {
  .trade-chart-container {
    padding: 13px;
    margin-top: 52px !important;
    background: #f3eded;
    border-radius: 16px;
    height: 260px;
    /* width: 81%; */
    display: flex;
    margin: auto;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .lable-position {
    display: flex;
  }

  .m-auto {
    margin: auto;
  }

  #imp-tab {
    margin: auto;
    border-spacing: 11px 0rem;
    border-collapse: separate;
  }

  .exp-tab {
    margin: auto;
    border-spacing: 11px 0rem;
    border-collapse: separate;
  }

  .imp-exp-tab-value {
    font-size: 18px;
    font-weight: 600;
  }

  #summary {
    margin-top: auto;
    margin-bottom: auto;
    width: 33%;
    height: 168px;
    color: #005d91;
    position: relative;
    margin-bottom: 16px;
    padding: 10px 10px 10px 0px;
  }

  #SummaryTradeChartContainer>.SummaryDetails-card-chartBox {
    // padding: 10px 20px 10px 20px;
    padding: 0px 0.6% 1.2% 0.6%
  }

  .summaryPipe {
    height: 1px;
    background: linear-gradient(96deg, rgba(255, 255, 255, 1) 1%, rgba(0, 93, 145, 1) 50%, rgba(255, 255, 255, 1) 99%) !important;
    width: 100%;
  }

  #SummaryTradeChartContainer {
    padding: 0px 0.6%;
  }

  .set-chart-zoom {
    @media (max-width: 1600px) {
      zoom: 1.34;
    }
  }


  .set-font-size {
    font-size: 20px;
  }

  .summary-tab-data {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: left;
  }

  #tradeSummary-chart {
    height: 400px;
    width: 800px;
  }

  #export-tradeSummary-chart {
    height: 400px;
    width: 800px;
  }

  #linkedIn-comapany-search {
    cursor: pointer;
  }

  .highcharts-plot-background {
    /* display: block; */
    width: 100px !important;
  }

  #summary-page-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 36px;
  }

  .align-left {
    text-align: left;
  }

  .set-chart-style {
    width: 100%;
  }

  .set-drilldown-style {
    width: 100%;
    height: 1000px;
  }

  .set-price-qauntity-country-chart {
    width: 800px;
    height: 500px;
  }

  .set-chart-container {
    display: flex;
  }

  .network-chart {
    height: 550px;
    width: 800px;
  }

  .left-count-alert {
    margin-top: 10px;
  }

  select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.9rem + 2px);
    padding: 0.45rem 1.9rem 0.45rem 0.9rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;

    &:focus {
      border-color: #b1bbc4;
      outline: 0;
    }

    option {
      font-size: 16px;
    }
  }
}