// Variables
$primary-color: #3b82f6;
$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;
$white: #ffffff;
$labelColor: #005d91;

// Mixins
@mixin flex-center {
  display: flex !important;
  align-items: center !important;
}

@mixin button-base {
  padding: 0.5rem 1rem !important;
  border-radius: 0.375rem !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  transition: background-color 0.2s !important;

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
  }
}

// Global styles
.container-log {
  min-height: 100vh !important;
  background-color: $gray-50 !important;
  // padding: 1.5rem !important;
  padding: 12px 30px 12px 6px;

  .content {
    // max-width: 80rem !important;
    margin: 0 auto !important;
  }
}

// Header styles
.header {
  margin-bottom: 1.5rem !important;

  h1 {
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    color: $gray-900 !important;
    margin-bottom: 1rem !important;
  }

  .search-container {
    position: relative !important;

    .search-icon {
      position: absolute !important;
      left: 0.75rem !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
      color: $gray-400 !important;
    }

    input {
      width: 100% !important;
      padding: 0.5rem 1rem 0.5rem 2.5rem !important;
      border: 1px solid $gray-300 !important;
      border-radius: 0.5rem !important;
      font-size: 0.875rem !important;

      &:focus {
        outline: none !important;
        border-color: $primary-color !important;
        box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
      }
    }
  }
}

// Importer panel styles
.importer-panel {
  background-color: $white !important;
  border: 1px solid $gray-200 !important;
  border-radius: 0.5rem !important;
  // margin-bottom: 1rem !important;
  margin-bottom: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;

  .panel-header {
    @include flex-center;
    justify-content: space-between !important;
    // padding: 10px 30px 5px 10px !important;
    padding: 2px 12px 2px 4px !important;

    &:hover {
      background-color: #c2d8e594 !important;
    }

    .header-left {
      @include flex-center;
      // gap: 1.5rem !important;

      .company-info {
        h2 {
          font-size: 18px !important;
          font-weight: 600 !important;
          color: $gray-900 !important;
          margin-top: 10px;
        }

        p {
          font-size: 0.875rem !important;
          color: $gray-600 !important;
        }

        .extra-detail-container {
          // overflow-x: auto !important; // Enables horizontal scrolling if needed
          // max-width: 100% !important; // Ensures it does not exceed the container width
          width: 900px;

          .main-detail-table {
            border-collapse: collapse !important;
            width: 400px;
            border-right: 1px solid #d0d1d2;
            padding-right: 10px;

            tbody {
              display: flex !important;
              flex-direction: row !important;
              white-space: nowrap !important;
              // max-width: 1050px;

              .table-row {
                display: flex !important;
                flex-direction: column !important;
                border-right: 1px solid #d0d1d2;
                margin: 0px 5px !important;
                padding-right: 7px !important;

                &:first-child {
                  margin: 0px 0px;
                }

                &:last-child {
                  border-right: none !important;
                }

                .fixed-column {
                  position: sticky !important;
                  left: 0 !important;
                  z-index: 2 !important; // Keeps it above other scrolling elements
                  min-width: 150px !important;
                  max-width: 200px !important;
                  text-align: left !important;
                }

                .header-table-data {
                  padding: 5px 10px;
                  font-size: 14px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  // max-width: 457px;
                  // min-width: 180px;
                  width: 300px;
                  font-weight: 600 !important;
                  color: #000000cc;
                }

                .tableData {
                  padding: 5px 10px;
                  font-size: 14px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  max-width: 150px;
                }

                .extra-label {
                  font-weight: 600 !important;
                  color: $labelColor !important;
                  text-transform: capitalize !important;
                }

                .extra-value {
                  color: $gray-800 !important;
                  // overflow: hidden !important;
                  // text-overflow: ellipsis !important;
                  // white-space: nowrap !important;
                }
              }
            }
          }

          // .scroll-table-extra-detail {
          //   overflow-x: scroll;
          //   min-width: 500px;
          //   max-width: 700px;
          // }

          .scroll-table-extra-detail {
            overflow-x: auto !important;
            // min-width: 500px !important;
            // max-width: 700px !important;
            white-space: nowrap !important;
            width: 800px;

            // Customizing scrollbar for a white/soft theme
            &::-webkit-scrollbar {
              height: 6px !important; // Slimmer scrollbar for a cleaner look
            }

            &::-webkit-scrollbar-track {
              background: #ffffff !important; // Pure white track
              border-radius: 3px !important;
            }

            &::-webkit-scrollbar-thumb {
              background: #e0e0e0 !important; // Light grey for subtle contrast
              border-radius: 3px !important;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #cccccc !important; // Slightly darker grey on hover
            }
          }

          .extra-detail-table {
            width: max-content !important; // Ensures table expands based on content
            border-collapse: collapse !important;
            // min-width: 600px !important;
            overflow-x: auto !important;

            tbody {
              display: flex !important;
              flex-direction: row !important;
              white-space: nowrap !important;
              max-width: 1050px;

              .table-row {
                display: flex !important;
                flex-direction: column !important;
                border-right: 1px solid #d0d1d2;
                margin: 0px 5px !important;
                padding-right: 7px !important;

                &:first-child {
                  margin: 0px 0px;
                }

                &:last-child {
                  border-right: none !important;
                }

                .fixed-column {
                  position: sticky !important;
                  left: 0 !important;
                  z-index: 2 !important; // Keeps it above other scrolling elements
                  min-width: 150px !important;
                  max-width: 200px !important;
                  text-align: left !important;
                }

                // td {
                //   padding: 5px 10px;
                //   font-size: 14px;
                //   overflow: hidden;
                //   text-overflow: ellipsis;
                //   white-space: nowrap;
                //   max-width: 150px;
                // }

                .header-table-data {
                  padding: 5px 10px;
                  font-size: 14px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  // max-width: 150px;
                  max-width: 457px;
                  min-width: 180px;
                }

                .tableData {
                  padding: 5px 10px;
                  font-size: 14px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  max-width: 150px;
                }

                &.address-row {
                  td {
                    max-width: 318px !important;
                    /* min-width: 170px !important; */
                    overflow-x: hidden !important;
                    display: block !important;
                    text-overflow: ellipsis !important;
                    width: 280px;
                  }
                }

                .extra-label {
                  font-weight: 600 !important;
                  color: $labelColor !important;
                  text-transform: capitalize !important;
                }

                .extra-value {
                  color: $gray-800 !important;
                  // overflow: hidden !important;
                  // text-overflow: ellipsis !important;
                  // white-space: nowrap !important;
                }
              }
            }
          }
        }
      }
    }

    .header-right {
      @include flex-center;
      gap: 1rem !important;
      margin-right: 15px;

      .stat {
        text-align: right !important;

        .label {
          font-size: 0.875rem !important;
          color: $labelColor !important;
        }

        .value {
          font-weight: 600 !important;
          color: $gray-900 !important;
        }
      }
    }
  }

  .panel-content {
    padding: 5px 10px 5px 40px !important;
    background-color: #c2d8e5de !important;

    .detail-section {
      .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-left {
          @include flex-center;
          gap: 0.5rem !important;

          font-size: 14px !important;
          font-weight: 600 !important;
          color: $gray-900 !important;
        }

        .header-stats {
          @include flex-center;
          gap: 1rem !important;
          font-size: 14px !important;
          margin-right: 80px;

          .stat {
            text-align: right !important;
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 130px;

            .label {
              font-size: 0.875rem !important;
              color: $labelColor !important;
            }

            .value {
              font-weight: 600 !important;
              color: $gray-900 !important;
              display: block;
            }
          }
        }
      }
    }
  }
}

// Details table styles
.details-table {
  width: 100% !important;
  border-collapse: collapse !important;

  th,
  td {
    padding: 0.5rem 0.75rem !important;
    text-align: left !important;
    font-size: 0.75rem !important;
  }

  th {
    background-color: $gray-50 !important;
    color: $gray-500 !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
  }

  td {
    color: $gray-700 !important;
    border-top: 1.5px solid $gray-200 !important;
  }

  tbody tr:hover {
    background-color: $gray-50 !important;
  }
}

// Pagination styles
.pagination {
  @include flex-center;
  justify-content: space-between !important;
  padding: 0.75rem 1rem !important;
  background-color: $white !important;
  border-top: 1px solid $gray-200 !important;
  border-radius: 0.5rem !important;

  .pagination-info {
    font-size: 0.875rem !important;
    color: $gray-700 !important;
  }

  .pagination-controls {
    @include flex-center;
    gap: 0.25rem !important;

    button {
      @include button-base;

      &.active {
        background-color: $primary-color !important;
        color: $white !important;
      }

      &:not(.active) {
        border: 1px solid $gray-300 !important;
        color: $gray-700 !important;

        &:hover {
          background-color: $gray-50 !important;
        }
      }
    }
  }
}

.expand-icon {
  cursor: pointer !important;
  color: gray;
}

.logisticLabelColor {
  color: $labelColor !important;
}
