// * :not(.ag-theme-quartz):not(.ag-theme-quartz *) {
//   font-family: "Rubik", sans-serif !important;
//   font-optical-sizing: auto;
//   /* font-weight: 700 !important; */
//   // font-style: normal;
// }
// * {
//   font-family: "Rubik", sans-serif;
//   font-size: 14px;
//   /* font-weight: 400; */
// }

::-webkit-scrollbar-track {
  // background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  // background-color: #005d91;
  border-radius: 20px;
}

::-webkit-scrollbar {
  height: 12px !important;
}

.ag-cell {
  font-weight: 400;
  // height: 50px !important;
  div {
    white-space: nowrap !important;
  }
}
.ag-watermark {
  display: none !important;
}
.ag-header {
  background: #c2d8e5 !important;
  height: 62px !important;
  min-height: 62px !important;
  padding-top: 6px !important;
}
.ag-header-cell-text {
  color: #005d91 !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  word-spacing: 2px;
}

.ag-row-even {
  background-color: #ffffff !important;
}

.ag-row-odd {
  background-color: #ecf0f2 !important;
}
.ag-row {
  height: 50px !important;
  color: #000000 !important;
}

.ag-header-cell-resize::after {
  background-color: white !important;
}

.ag-icon-custom-stats {
  background: transparent
    url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgc3Ryb2tlPSIjN0Y4QzhEIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMC41IDZWNC41aC01di41MzJhMSAxIDAgMCAwIC4zNi43NjhsMS43MTggMS40MzJhMSAxIDAgMCAxIDAgMS41MzZMNS44NiAxMC4yYTEgMSAwIDAgMC0uMzYuNzY4di41MzJoNVYxMCIvPjxyZWN0IHg9IjEuNSIgeT0iMS41IiB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHJ4PSIyIi8+PC9nPjwvc3ZnPg==)
    no-repeat center;
  background-size: 16px 16px;
  height: 16px;
  opacity: 0.87;
  width: 16px;
  max-width: 16px;
  display: inline-block;
}

.ag-body {
  flex: 0 0 auto !important;
}

.highlight-text {
  background-color: #d0ebff;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 4px;
  color: #0056b3;
}
