.collapse-header {
  padding-left: 25px;
  padding-right: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid rgb(0 0 0 / 10%);
  border-radius: 0px 7px 7px 0px;
  box-shadow: 1px 0px 3px 2px rgba(194, 216, 229, 1);
}
