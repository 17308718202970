#ExploreCountryShipment {
  .bg-block {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000052;
    width: 100% !important;
    height: 100%;
  }

  .ExploreCountryShipment-main-container {
    .exploreCountryShipment-select-container {
      // box-shadow: 0px 2px 1px -1px rgb(255 255 255 / 20%), 0px 1px 1px 0px rgb(193 189 189 / 14%), 0px 1px 3px 0px rgb(183 160 160 / 12%);
      position: sticky;
      top: 0;
      z-index: 5000;
      background-color: #ffffff;
      // box-shadow: rgb(173 173 173 / 21%) 0px 25px 20px -20px;
    }

    .exploreCountryShipment-search-container {
      margin: 5px 0px;
      min-height: 100px;

      .search_advancesearch_container {
        width: 94%;

        .set-red {
          color: red !important;
          font-weight: 700;
        }

        .set-search-count {
          color: rgb(108, 117, 125);
          font-size: 14px;
          padding-bottom: 10px;
          width: 100%;
        }
      }
    }

    .summary-btn-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row;
      margin: 10px 0px;

      .summary {
        display: flex;
        justify-content: space-between;
      }
    }

    .summary-btn-container .summary :nth-child(1) {
      margin-left: 0px !important;
    }

    .summary-explore {
      @media screen and (max-width: 1096px) {
        width: 100%;
      }
    }

    .pipe-line {
      width: 2px;
      height: 100%;
      margin-right: 5px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 1%,
        rgb(8 12 14) 50%,
        rgba(255, 255, 255, 1) 99%
      ) !important;
    }

    .exploreData-filter {
      padding: 10px 5px 0px 5px !important;

      .exploreFilter-div {
        padding-left: 0px;
        padding-top: 0px;
        margin-top: 16px;
        border-radius: 0px 8px 0px 0px;

        #exploreFilter {
          position: sticky;
          top: 76px;
          z-index: 1000;
        }
      }

      .filter-lable-off {
        width: 4%;
        padding: 0px 0px 0px 10px;
        position: sticky;
        top: 61px;
        left: 1px;
        z-index: 10100;

        #set-filters-showing-arrow {
          cursor: pointer;
          padding: 0px 4px;
          height: 42px;
          top: 24px;
          left: 4px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          position: absolute;
          background: #005d91;
          color: white;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          width: 115px;
        }
      }
    }
  }
}
