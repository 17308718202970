.combine-tab-1 {
  #dataTable-table tbody {
    visibility: collapse;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.combine-tab-2 #dataTable-table thead {
  visibility: collapse;
}

// .combine-tab-1-safari {
//     display: none;
// }
.ios_table_one {
  #ios_dataTable-table tbody {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.ios_table_two #ios_dataTable-table thead {
  display: none;
}

// .combine-tab-1-safari {
//     display: none;
// }
