#error-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 470px;
  background: white;
  box-shadow: 2px;
  border-top: 3px solid;
  // padding: 25px 0px;
  outline: none;
  border-radius: 7px;
  @media (max-width: 500px) {
    width: 100%;
  }
  .all_model {
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 470px) {
      word-break: break-word;
      padding: 10px 10px !important;
    }
    .all_model_image {
      height: 100px;
      width: 100px;
      @media (max-width: 507px) {
        height: 70px;
        width: 70px;
      }
    }
  }

  #anchor {
    font-size: 16px;
    text-decoration: none;

    &:hover {
      color: rgb(3, 38, 170) !important;
    }

    @media (max-width: 575px) {
      width: 2% !important;
      word-break: break-word;
    }
  }

  .css-1v425uy {
    @media (max-width: 576px) and (min-width: 441px) {
      width: 62% !important;
    }

    @media (max-width: 576px) and (min-width: 441px) {
      width: 62% !important;
    }

    @media (max-width: 440px) {
      width: 57% !important;
    }
  }

  #refreshWidth {
    @media (max-width: 328px) and (min-width: 320px) {
      width: 187px !important;
    }
  }
}
