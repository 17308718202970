#ConfirmationModal {
  .confirmationModal-styles {
    position: absolute;
    top: 50%;
    left: 50%;
    border-top: 3px solid red;
    border-radius: 6px 6px 6px 6px !important;
    font-family: "Rubik" !important;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 24;
    outline: none;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    // height: 80%;
    // overflow: auto;
    @media (max-width: 700px) {
      width: 92% !important;
    }
  }

  .confirmationModal-modalBody {
    font-family: "Rubik" !important;
    padding: 15px 15px 15px 15px !important;
  }

  .delete-close {
    font-family: "Rubik";
    color: #909090;
    background-color: #ffffff;
    border-color: #909090 !important;
    border-width: 0.5px 3px 3px 0.5px !important;
    border-style: solid !important;
    border-radius: 6px !important;
    &:hover {
      color: #ffffff;
      background-color: #909090;
      border-color: #909090;
    }
  }

  .hr {
    font-family: "Rubik";
    border: 1px solid #dee2e6;
  }
  // .footer-modal{
  //   position: sticky;
  //   bottom: 0;
  //   background-color: white;
  // }
  .footer-confirmationModal-content {
    font-family: "Rubik";
    padding: 10px 10px;
    float: right;
  }
}
