#searchComponentExplore {
  .clear-search-input {
    // position: absolute;
    font-style: normal;
    font-size: 1.4em;
    user-select: none;
    color: #6c757d;
    margin-left: -65px !important;
    right: 30px;
    cursor: pointer;
    @media (min-width: 458px) {
      margin-left: -23px !important;
      margin-top: 5px;
    }
    @media (max-width: 457px) {
      right: 0;
      top: 1px;
      padding: 5px 10px;
    }
  }
  .inline-container {
    display: inline-block;
  }
}
