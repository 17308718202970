.report-style-class {
  height: calc(100vh - 257px);
  @media (max-width: 1720px) and (min-width: 1600px) {
    zoom: 1.064 !important;
  }
  @media (max-width: 1599px) {
    zoom: 1.4 !important;
    height: calc(100vh - 4px);
  }
}
