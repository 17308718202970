#search-container-explore {
  .search-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .search-item {
      display: flex;
      flex-direction: column;

      .ant-space-item > .ant-picker {
        top: 0px !important;
      }

      .ant-space {
        margin: 5px 16px 8px 0px !important;
      }

      .search-item-label {
        margin-left: 10px;
        font-weight: 500;
      }

      .action-btn {
        display: flex;
        flex-direction: row;
      }

      .search-form-control {
        width: 13rem;
        margin: 5px 8px 8px 8px;
      }

      .search-async {
        height: 40px !important;
        width: 20vw !important;
        z-index: 4002 !important;

        @media (max-width: 800px) and (min-width: 500px) {
          width: 40vw !important;
        }

        @media (max-width: 500px) {
          width: 45vw !important;
        }

        @media (max-width: 420px) {
          width: 80vw !important;
        }

        .css-xb97g8 {
          cursor: pointer;
        }

        .css-15pchde-control {
          height: 40px !important;
          min-height: 40px !important;
        }
      }
    }
    .tooltip-position-relative {
      position: relative;
      .tooltip-position-absolute {
        position: absolute;
        z-index: 10000000000;
        top: 68px;
        left: -114px;
        @media (min-width: 0px) and (max-width: 239px) {
          left: 0px;
        }
        @media (min-width: 421px) and (max-width: 500px) {
          left: 0px;
        }
      }
    }

    .workSpaceBtnClass {
      margin-left: auto !important;
    }

    .workSpaceBtnClass .action-btn > button:last-child {
      margin-right: 0px !important;
    }
  }
}
