.fav-company-card-container {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr) !important;
  gap: 25px !important;
  overflow-y: scroll;
  padding: 0.6%;

  @media (min-width: 769px) and (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  @media (min-width: 769px) and (max-width: 1439px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  @media (min-width: 700px) and (max-width: 1135px) {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  @media (min-width: 0px) and (max-width: 699px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .info-card {
    max-width: 100% !important;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
}
