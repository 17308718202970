.paddingAllLeftRight_training {
  padding: 0% 0% 0% 0%;
  .training_sidebar {
    padding: 20px 0px;
    height: calc(100vh - 181px);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgb(216 227 233);
    position: sticky;
    top: 0px;

    @media (min-width: 320px) and (max-width: 1600px) {
      height: calc(100vh + 84px);
    }
    @media (min-width: 1601px) and (max-width: 1700px) {
      height: calc(100vh + 84px);
    }
    @media (min-width: 1601px) {
      height: calc(100vh - 181px);
    }

    .toggle_sidebar {
      display: flex;
      justify-content: flex-end;
    }
  }
  .mobile_screen_sidebar {
    position: absolute !important;
    top: 0px;
    left: 0px;
    z-index: 10;
  }
  .hidden {
    display: none;
  }

  .collar {
    width: 60px;
    height: 30px;
    background: #d9d9d9;
    border-radius: 0px 10px 10px 0px;
    margin-top: 20px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
  }

  .collar_left {
    width: 60px;
    height: 30px;
    background: #d9d9d9;
    border-radius: 10px 0px 0px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .training_video {
    width: 100%;
    height: 70%;
  }
  .video-index-image {
    background-image: url("../../Asset/images/videobackground.png");
    background-size: cover;
    padding: 4% 1%;
    margin-bottom: 25px;
    border-radius: 6px;
  }
  .closed-sidebar-video {
    max-width: 100%;
    max-height: calc(100vh - 300px);

    @media (max-width: 1600px) {
      max-height: 100vh;
    }
  }
  #videoTag {
    position: relative;
    max-width: 100%;
    width: 100%;
    @media (max-width: 1600px) {
      zoom: 1.33;
    }
    @media (max-width: 1720px) and (min-width: 1600px) {
      zoom: 1.34;
    }
  }
}
.title-div-training {
  @media (max-width: 768px) {
    padding-top: 30px;
  }
}
#collapse-tree-video {
  background-color: #ecf0f2;
  background-color: black;
  padding: 20px 20px 20px 0px;
}
.video_index {
  display: flex;
  align-items: center;
  background-color: #c2d8e5;
  width: 100%;
  height: 45px;
  border-radius: 0px 10px 0px 0px;
}
// .title-div {
//   margin-left: 7%;
// }
.full_training_video {
  background-color: #c2d8e5;
  width: 100%;
  height: 45px;
  border-radius: 0px 10px 0px 0px;
}
.zoom_out_1_33 {
  zoom: 1.33 !important;
}

.center-video {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video_content {
  margin-top: 20px;
}
.next_previous_button {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.content_buttons {
  display: flex;
  justify-content: space-between;
}
.feedback_button {
  display: flex;
  gap: 20px;
}
.next_previous_button {
  display: flex;
  gap: 20px;
}
.video_data {
  margin: 20px 0px;
  .video_data_content {
    .training-title {
      color: #005d91 !important;
    }
    .training-video-info,
    .training-activation-steps {
      margin-bottom: 20px !important;
    }
    .training-video-link {
      text-decoration: none !important;
      color: #007bff !important;
      cursor: pointer !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
    .font-size {
      font-size: 18px;
    }
    .letter-spacing {
      letter-spacing: 0.5px;
    }
    .text-indent {
      text-indent: 2%;
    }
    .styled-div {
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      padding: 0.1% 2%;
    }
    .bulletpoints li {
      list-style-type: disc !important; /* Use disc as the bullet point style */
      margin-left: 20px; /* Adjust margin as needed */
    }
    .step-img {
      margin: 20px 0 !important;
      /* Add margin for the image */
    }
  }
}
img {
  max-height: 100%;
  max-width: 100%;
}
rect {
  cursor: pointer;
}
