#navbarComponent {
  position: sticky;
  top: 0px;

  // display: flex;
  // justify-content: space-between;
  // flex-direction: column;
  // height: 100%;
  // min-height: 100vh;
  .header-logo-lg img {
    height: 36px;
    vertical-align: middle;
    border-style: none;
    margin: 13px;
  }

  .goog-te-combo {
    position: relative;
    right: 15px;
    top: 23px;
    padding: 5px;
    width: 160px;
    border: 1px solid #ced4da;
    border-radius: 5px;
  }

  .pt-15 {
    padding-top: 15px;
  }

  .px-py {
    padding: 4px 18px !important;
  }

  .middle-content-height {
    @media (max-width: 1720px) {
      height: calc(100vh + 85px);
    }
  }

  #middle-content {
    @media (max-height: 752px) and (min-height: 748px) {
      height: calc(100vh + 57px);
    }

    @media (max-height: 748px) and (min-height: 743px) {
      height: calc(100vh + 55px);
    }

    @media (max-height: 743px) and (min-height: 738px) {
      height: calc(100vh + 53px);
    }

    @media (max-height: 738px) and (min-height: 731px) {
      height: calc(100vh + 51px);
    }

    @media (max-height: 731px) and (min-height: 725px) {
      height: calc(100vh + 49px);
    }

    @media (max-height: 731px) and (min-height: 719px) {
      height: calc(100vh + 47px);
    }

    @media (max-height: 719px) and (min-height: 713px) {
      height: calc(100vh + 45px);
    }

    @media (max-height: 713px) and (min-height: 708px) {
      height: calc(100vh + 43px);
    }

    @media (max-height: 708px) and (min-height: 702px) {
      height: calc(100vh + 41px);
    }

    @media (max-height: 702px) and (min-height: 695px) {
      height: calc(100vh + 39px);
    }

    @media (max-height: 695px) and (min-height: 689px) {
      height: calc(100vh + 37px);
    }

    @media (max-height: 689px) and (min-height: 683px) {
      height: calc(100vh + 35px);
    }

    @media (max-height: 683px) and (min-height: 677px) {
      height: calc(100vh + 33px);
    }

    @media (max-height: 677px) and (min-height: 671px) {
      height: calc(100vh + 31px);
    }

    @media (max-height: 671px) and (min-height: 665px) {
      height: calc(100vh + 29px);
    }

    @media (max-height: 665px) and (min-height: 659px) {
      height: calc(100vh + 27px);
    }

    @media (max-height: 659px) and (min-height: 653px) {
      height: calc(100vh + 25px);
    }

    @media (max-height: 653px) and (min-height: 647px) {
      height: calc(100vh + 23px);
    }

    @media (max-height: 647px) and (min-height: 641px) {
      height: calc(100vh + 21px);
    }

    @media (max-height: 641px) and (min-height: 635px) {
      height: calc(100vh + 19px);
    }

    @media (max-height: 635px) and (min-height: 629px) {
      height: calc(100vh + 17px);
    }

    @media (max-height: 629px) and (min-height: 623px) {
      height: calc(100vh + 15px);
    }

    @media (max-height: 623px) and (min-height: 617px) {
      height: calc(100vh + 13px);
    }

    @media (max-height: 617px) {
      height: 100vh;
    }

    // @media (max-height: 748px) and (min-height: 748px) {
    //   height: calc(100vh + 47px);
    // }

    // @media (max-width: 526px) {
    //   height: calc(100vh - 228px);
    // }
  }

  .navbarWithScroll {
    @media (max-width: 1600px) {
      min-height: calc(100vh + 83px);
    }

    min-height: calc(100vh - 181px);
  }

  .tradeBody {
    height: calc(100vh + 160px);

    @media (min-width: 1600px) {
      height: calc(100vh - 67px) !important;
    }

    @media (min-width: 1720px) {
      height: calc(100vh - 121px) !important;
    }

    // height changes
    @media (min-height: 821px) and (max-height: 835px) {
      height: calc(100vh + 156px);
    }

    @media (min-height: 801px) and (max-height: 820px) {
      height: calc(100vh + 152px);
    }

    @media (min-height: 786px) and (max-height: 800px) {
      height: calc(100vh + 144px);
    }

    @media (min-height: 771px) and (max-height: 785px) {
      height: calc(100vh + 138px);
    }

    @media (min-height: 761px) and (max-height: 770px) {
      height: calc(100vh + 135px);
    }

    @media (min-height: 731px) and (max-height: 760px) {
      height: calc(100vh + 125px);
    }

    @media (min-height: 721px) and (max-height: 730px) {
      height: calc(100vh + 118px);
    }

    @media (min-height: 701px) and (max-height: 720px) {
      height: calc(100vh + 115px);
    }

    @media (min-height: 691px) and (max-height: 700px) {
      height: calc(100vh + 110px);
    }

    @media (min-height: 681px) and (max-height: 690px) {
      height: calc(100vh + 106px);
    }

    @media (min-height: 651px) and (max-height: 680px) {
      height: calc(100vh + 104px);
    }

    @media (min-height: 621px) and (max-height: 650px) {
      height: calc(100vh + 90px);
    }

    @media (min-height: 601px) and (max-height: 620px) {
      height: calc(100vh + 80px);
    }

    @media (min-height: 513px) and (max-height: 600px) {
      height: calc(100vh + 78px);
    }

    @media (max-height: 512px) {
      height: calc(100vh + 48px);
    }

    @media (max-height: 499px) {
      height: calc(100vh + 38px);
    }
    @media (max-height: 465px) {
      height: calc(100vh + 32px);
    }
  }

  .navbarWithStickyHeader {
    @media (min-width: 1600px) {
      height: calc(100vh - 181px);
    }

    overflow: hidden overlay;
  }

  #navbar-item {
    color: rgba(255, 255, 255, 0.7);
    border: none;
    position: relative;
    bottom: 6px;
    text-transform: none;
    font-size: 14px;

    // font-weight: bold;
    // padding-top: 4px;
    // margin-bottom: 20px;
    &:hover {
      color: rgba(255, 255, 255, 0.952) !important;
    }
  }

  #header-navbar {
    padding: 4px;
    width: 300px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  // #nav-icons {
  //   position: relative;
  //   top: 5px;
  //   padding: 0 4px;
  //   //   margin-left: 10px;
  // }

  .notification-pooper {
    transform: translate3d(-298px, 0px, 0px) !important;
  }

  #notificationBell {
    &:active {
      background-color: #d3c8c8;
    }
  }

  #notification-modal {
    width: 300px;
    height: 300px;
    margin: 0px !important;
    padding-top: 0px !important;
  }

  .set-style {
    font-size: 14px;
    font-family: "Rubik" !important;
    font-weight: 600;
  }

  #parent-menu {
    position: relative;
  }

  #footer-anchor {
    &:hover {
      color: rgb(38, 38, 170);
    }

    //   @media (max-width: 526px) {
    //     font-size: xx-small !important;
    //   }
  }

  .footer-content {
    &:hover {
      color: #323a46 !important;
    }

    @media (max-width: 526px) {
      display: block;
      text-align: center;
    }
  }

  #foot {
    // @media (min-width: 1510px) {
    //   position: fixed;
    // }
    // @media (max-width: 1510px) {
    //   bottom: 0px;
    // }
    @media (max-width: 526px) {
      display: block;
    }
  }

  #devlop-parent {
    @media (max-width: 526px) {
      display: block;
      text-align: center;
    }
  }

  .about-contact {
    text-align: center;
    display: flex;
    margin-right: 7rem;

    @media (max-width: 526px) {
      display: contents;
    }

    @media (max-width: 1720px) {
      margin-right: 10rem;
    }
  }

  #contact-us {
    @media (max-width: 526px) {
      margin-left: 14px;
    }
  }

  #contact {
    margin: 0px 30px;
    font-size: 14px;

    @media (max-width: 526px) {
      text-align: center;
    }
  }

  ul {
    padding: 0;
    list-style: none;

    margin-left: 18px;

    li:hover ul.dropdown {
      display: block;
      /* Display the dropdown */

      li {
        display: block;
      }
    }

    li {
      #user-avatar {
        display: flex;
        position: relative;
        top: 0px;
        left: 10px;
      }

      display: inline-block;
      position: relative;

      text-align: left;

      a {
        font-size: 15.5px;
        display: block;
        font-family: "Rubik" !important;

        padding: 10px 22px;

        color: rgba(255, 255, 255, 0.7);
        text-decoration: none;
      }

      a:hover {
        color: white;
      }

      ul.dropdown {
        min-width: 200px;
        /* Set width of the dropdown */
        background: white;
        display: none;
        position: absolute;
        z-index: 999;
        left: 0;

        .manage-position {
          position: relative;
          bottom: 9px;
          padding: 0 4px;
        }

        .dropdownItem {
          font-family: "Rubik";
          color: rgb(39, 37, 37) !important;
          display: flex;

          .userInfoDetails {
            font-weight: 400 !important;
            color: #6c757d;
            font-size: 14px !important;
            font-family: "Rubik" !important;
          }

          .set-user-position {
            margin-right: 7px;
            color: #6c757d;
          }
        }

        // .dropdownItem:hover {
        //   background-color: #ededed !important;
        // }
      }
    }
  }

  .set-position {
    position: relative;
    bottom: 8px;
    right: 3px;
  }

  .nav-arrow-icon {
    position: relative;
    bottom: 3px;
    left: 2px;
  }

  #userInfo {
    display: flex;
  }

  #logout {
    border-top: 1px solid rgb(242, 239, 239);
    margin: 5px 0px;
  }

  #user-dropdown {
    position: absolute;
    top: 40px;
    right: 10px;
    left: 10px;
    z-index: 10;

    @media (max-width: 744px) {
      left: -117px;
    }
  }

  #notificationID {
    position: relative;
    left: 1000px;
  }

  .change-hover-color {
    &:hover {
      color: rgba(255, 255, 255, 0.952) !important;
    }
  }

  nav {
    width: 100%;
    height: 100%;
    height: 60px;
    // background: #3E8DA8;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    z-index: 99;

    .navbar {
      height: 100%;

      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: auto;
      padding-left: 26px;

      @media (max-width: 920px) {
        max-width: 100%;
        padding: 0 25px;
      }

      .bx-menu {
        display: none;

        @media (max-width: 970px) {
          display: block;
          font-size: 25px;
          color: #fff;
        }
      }

      .nav-links.show1 {
        #navbar-hover-item {
          .htmlCss-sub-menu {
            z-index: 1111111 !important;

            @media (max-width: 970px) {
              display: block;
            }
          }

          .country-nav-arrow {
            @media (max-width: 970px) {
              transform: rotate(180deg);
            }
          }
        }
      }

      .nav-links.show3 {
        #navbar-hover-item {
          .js-sub-menu {
            @media (max-width: 970px) {
              display: block;
            }
          }

          .workspace-nav-arrow {
            @media (max-width: 970px) {
              transform: rotate(180deg);
            }
          }
        }
      }

      .nav-links.show4 {
        #navbar-hover-item {
          .user-sub-menu {
            @media (max-width: 970px) {
              display: block;
            }
          }

          .user-arrow {
            @media (max-width: 970px) {
              transform: rotate(180deg);
            }
          }
        }
      }

      .nav-links.show5 {
        #navbar-hover-item {
          .favourite-sub-menu {
            @media (max-width: 970px) {
              display: block;
            }
          }

          .favourite-arrow {
            @media (max-width: 970px) {
              transform: rotate(180deg);
            }
          }
        }
      }

      .nav-links.show6 {
        #navbar-hover-item {
          .market-sub-menu {
            @media (max-width: 970px) {
              display: block;
            }
          }

          .market-nav-arrow {
            @media (max-width: 970px) {
              transform: rotate(180deg);
            }
          }
        }
      }

      .nav-links.show7 {
        #navbar-hover-item {
          .request-sub-menu {
            @media (max-width: 970px) {
              display: block;
            }
          }

          .request-nav-arrow {
            @media (max-width: 970px) {
              transform: rotate(180deg);
            }
          }
        }
      }

      .nav-links {
        line-height: 84px;
        height: 100%;
        display: block;
        width: 100%;

        @media (max-width: 970px) {
          display: block;
          font-size: 25px;
          color: #fff;
        }

        @media (max-width: 970px) {
          position: fixed;
          top: 60px;
          left: -100%;
          display: block;
          max-width: 270px;
          width: 100%;
          background: #005d91;
          line-height: 40px;
          padding: 20px;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
          transition: all 0.5s ease;
          z-index: 1000;
          height: 100%;
        }

        @media (max-width: 370px) {
          max-width: 242px;
        }

        .sidebar-logo {
          display: none;

          @media (max-width: 970px) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }

          .logo-name {
            @media (max-width: 970px) {
              font-size: 25px;
              color: #fff;
            }
          }

          i {
            @media (max-width: 970px) {
              font-size: 25px;
              color: #fff;
            }
          }
        }

        .links {
          display: flex;
          margin: auto;
          height: 100%;
          flex-direction: row;
          justify-content: space-between;

          @media (max-width: 970px) {
            display: block;
            margin-top: 20px;
          }

          li:hover .account-arrow,
          li:hover .country-nav-arrow,
          li:hover .workspace-nav-arrow,
          li:hover .user-arrow,
          li:hover .favourite-arrow,
          li:hover .market-nav-arrow,
          li:hover .request-nav-arrow {
            transform: rotate(180deg);

            @media (max-width: 920px) {
              transform: rotate(0deg);
            }
          }

          li:hover .htmlCss-sub-menu,
          li:hover .js-sub-menu,
          li:hover .user-sub-menu,
          li:hover .favourite-sub-menu,
          li:hover .market-sub-menu,
          li:hover .request-sub-menu {
            display: block;
            z-index: 1111111 !important;

            @media (max-width: 970px) {
              display: none;
            }
          }

          li {
            position: relative;
            // display: flex;
            align-items: center;
            justify-content: space-between;
            list-style: none;
            padding: 0 14px;

            @media (max-width: 1150px) {
              padding: 0 7px;
            }

            @media (min-width: 0px) and (max-width: 970px) {
              padding: 0 9px !important;
            }

            @media (max-width: 920px) {
              padding: 0 7px;
              white-space: nowrap;
            }

            @media (max-width: 920px) {
              display: block;
            }

            .dash-position {
              position: relative;
              bottom: 4px;
              padding: "0 4px";
            }
            .dash-position-span {
              color: #adb5bd;
              cursor: pointer;
            }
            .dash-position-span:hover {
              color: white;
            }
            // .htmlCss-more-sub-menu {
            //   /* line-height: 40px; */
            // }

            #nav-icons {
              display: inline-block;
              height: 33%;

              .manage-position {
                position: relative;
                bottom: 5px;
                padding: 0 4px;
              }
            }

            a {
              height: 100%;
              text-decoration: none;
              white-space: nowrap;
              color: #fff;
              font-size: 15px;
              font-weight: 500;
              padding: 0px !important;

              @media (max-width: 920px) {
                font-size: 15px;
              }
              @media (min-width: 951px) and (max-width: 1004px) {
                font-size: 13.5px;
              }
              @media (min-width: 1005px) and (max-width: 1050px) {
                font-size: 14px;
              }
            }

            .arrow {
              /* background: red; */
              height: 100%;
              width: 22px;
              line-height: 70px;
              text-align: center;
              display: inline-block;
              color: #b1cddd !important;
              transition: all 0.3s ease;

              @media (max-width: 920px) {
                line-height: 40px;
              }
            }

            .sub-menu {
              position: absolute;
              transition: all 0.2s ease;
              top: 59px;
              left: 0;
              line-height: 40px;
              // background: #3e8da8;
              background: #fff;
              box-shadow: rgb(50 58 70 / 15%) 0px 0px 40px 0px;
              border-radius: 0 0 4px 4px;
              display: none;
              z-index: 2;
              min-width: 175px;
              margin-bottom: 20px;

              &:after {
                bottom: 100%;
                left: 52%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(204, 204, 204, 0);
                border-bottom-color: white;
                border-width: 10px;
                margin-left: -75px;
              }

              @media (max-width: 970px) {
                position: relative;
                top: 0;
                box-shadow: none;
                display: none;
              }

              li {
                padding: 0 22px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                @media (max-width: 970px) {
                  border-bottom: none;
                }
              }

              a {
                color: black !important;
                font-size: 15px;
                font-weight: 500;
              }

              a:hover {
                color: #005d91 !important;
              }

              .more-arrow {
                line-height: 40px;
              }

              .more-sub-menu {
                position: absolute;
                top: 0;
                left: 100%;
                border-radius: 0 4px 4px 4px;
                z-index: 1;
                display: none;

                @media (max-width: 970px) {
                  display: none;
                  position: relative;
                  left: 0;
                  // display: none;
                }

                li {
                  @media (max-width: 970px) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.dropdownItem-div {
  color: black;
  cursor: pointer;
}
.dropdownItem-div:hover {
  color: #005d91;
}
.remove-padding {
  padding: 0px 14px !important;
}
.dropdownItem-padding {
  padding: 6px 10px;
  cursor: pointer;
}
.dropdownItem-padding-activity {
  padding: 6px 10px !important;
  cursor: pointer;
}
.font-size-14px {
  font-size: 15px;
}
.font-size-15px {
  @media (max-width: 970px) {
    font-size: 15px;
  }
}
