.region_button {
  border: 0.3px solid rgba(0, 0, 0, 0.275);
  margin: 0px 5px !important;
  color: #66686a !important;
  padding: 6px 8px !important;
  font-weight: 500 !important;
  font-size: 15px;
  @media (max-width: 1350px) {
    font-size: 10px !important;
  }
  @media (max-width: 700px) {
    margin-bottom: 8px !important;
  }
}

.active_region {
  border: none !important;
  color: #fff !important;
  background-color: #005d91 !important;
}
.deactivate_region {
  border: 1px solid rgba(0, 0, 0, 0.275) !important;
}
