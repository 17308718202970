#advanceSearch-container-explore {
  .advanceSearch-container {
    display: flex;
    flex-direction: row;

    .advanceSearch-item {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .advanceSearch-item-label {
        margin-left: 10px;
      }

      .action-btn {
        display: flex;
        flex-direction: row;
      }

      .advanceSearch-form-control {
        height: 40px !important;
        width: 13rem;
        margin: 5px 8px 8px 8px;
      }

      .advanceSearch-async {
        width: 20vw !important;
        // z-index: 2000 !important;

        @media (max-width: 800px) and (min-width: 500px) {
          width: 40vw !important;
        }

        @media (max-width: 500px) {
          width: 45vw !important;
        }

        @media (max-width: 420px) {
          width: 80vw !important;
        }

        .css-xb97g8 {
          cursor: pointer;
        }

        .css-15pchde-control {
          height: 40px !important;
          min-height: 40px !important;
        }

        #react-select-7-listbox {
          z-index: 12000 !important;
        }
      }
    }

    .workSpaceBtnClass {
      margin-left: auto;
    }

    .workSpaceBtnClass .action-btn>button:last-child {
      margin-right: 0px !important;
    }
  }
}