#CompareTableWithCollapse {
    user-select: none !important;

    .CompareTableWithCollapse-header {
        font-family: "Rubik" !important;
        background-color: #C2D8E5;
        font-weight: 700;
        border: 1px solid white;
        // border-top: 3px solid white;
        // border-bottom: 3px solid white;
        font-size: 18px !important;
        color: #005D91;
    }

    .tableRowForDiff {
        border: 1px solid white;
        border-width: 1px;
        color: black;
        font-family: "Rubik" !important;
        max-width: 380px;
        text-align: left;
        background-color: #ECF0F2;
        white-space: nowrap;
    }

    .CompareTableWithCollapse-border-top-bottom {
        border-top: 3px solid white;
        border-bottom: 3px solid white;
    }

    .scroll {
        overflow-x: scroll;
    }

    #CompareTableWithCollapse-header>TableHead>:nth-child(1) {
        border-radius: 10px 0px 0px 10px;
    }

    .CompareTableWithCollapse-border-top {
        border-top: 3px solid white;
    }

    .CompareTableWithCollapse-border-bottom {
        border-bottom: 3px solid white;
    }

    .CompareTableWithCollapse-border-radius-top-right {
        border-radius: 0px 10px 0px 0px;
    }

    .CompareTableWithCollapse-border-radius-bottom-right {
        border-radius: 0px 0px 10px 0px;
    }

    .scroll::-webkit-scrollbar-track-piece {
        box-shadow: inset 0 0 3px rgb(128 128 128 / 37%);
        border-radius: 6px;
    }

    .scroll::-webkit-scrollbar {
        // display: block;
        position: absolute;
        width: 8px;
        height: 5px;
    }

    ::-webkit-scrollbar {
        // display: block;
        position: absolute;
        width: 9px;
        height: 9px;
    }

    .tableRowForDiff::-webkit-scrollbar-thumb {
        // background: rgb(142 132 132 / 9%);
        background-color: #70707016;
        border-radius: 6px;
        border-left: 1px solid rgba(94, 94, 94, 0.308);
        border-right: 1px solid rgba(94, 94, 94, 0.308);
        height: 3px;

        &:hover {
            background-color: rgb(158, 165, 171);
        }
    }

    .tableRowForDiff::-webkit-scrollbar {
        height: 5px !important;
    }

    #pagination {
        border-bottom-width: 1px solid #dee2e6 !important;
    }

    .css-bsph2u-MuiTableCell-root {
        padding: 14px 10px !important;
    }
}

#CompareTableWithCollapse .MuiPagination-root {
    position: relative;
    box-shadow: none;
    display: flex !important;
    justify-content: end !important;
}

#CompareTableWithCollapse .Mui-selected {
    background-color: #ffffff;
    color: black;
    border: 1px solid #005d91 !important;
    border-radius: 5px;
}