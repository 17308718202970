#dashboard {
  padding-left: 15px;
  padding-right: 15px;
  .dashboard-container {
    max-width: 100%;

    .dashbord-table-container {
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1) !important;
      height: calc(100vh - 181px - 178px) !important;
      padding: 0.5% !important;
      width: 100% !important;
      @media (min-width: 1200px) and (max-width: 1599px) {
        height: calc(100vh - 95px) !important;
      }

      @media (min-width: 769px) and (max-width: 1199px) {
        height: calc(100vh - 200px) !important;
      }
    }
  }
  // padding: 20px 100px;
  .btn-danger:hover {
    color: #fff;
    background-color: #ee324d;
    border-color: #ed2643;
  }

  .border-primary {
    border: 1px solid #005d91;
  }

  body {
    font-family: "Rubik" !important;
  }

  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .tableHeight {
    height: 260px;
    overflow-y: scroll;
    display: block;
  }

  .text {
    margin: 26px 0px !important;
    font-family: "Rubik" !important;
    font-size: 1.25rem !important;
  }

  .btn-danger {
    cursor: pointer;
    font-family: "Rubik" !important;
    color: #fff !important;
    width: 100%;
    height: 41px;
    background-color: red;
    border-color: red;
    font-size: 15px;
    border: none;
  }

  .avatar-lg {
    height: 4rem;
    width: 4rem;
    border: 1px solid #005d91;
    margin-right: 14px;
  }

  .bg-soft-primary {
    background-color: white;
  }

  .rounded-circle {
    border-radius: 50% !important;
    display: flex;
    align-items: center;
  }

  .ml-auto {
    margin-left: auto !important;
  }

  .text-muted {
    color: #98a6ad !important;
  }

  .d-flex {
    display: flex;
  }

  .font-22 {
    font-size: 22px !important;
  }

  .textStyles {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: "Rubik" !important;
    color: black !important;
  }

  .muted-text-black {
    color: black !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
