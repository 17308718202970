#ios_dataTable-table {
  .ios_table-header {
    position: sticky;
  }

  .MuiPagination-root {
    position: relative;
  }

  .MuiPagination-ul {
    position: absolute;
    right: 0px;
    top: 7px;
  }

  .Mui-selected {
    background-color: #005d91;
    color: white;
  }

  .table thead th {
    vertical-align: bottom;
  }

  #filterImage:hover {
    cursor: pointer;
    // transform: rotate(180deg);
    // transition: all 0.7s ease-in-out 0s;
    transform: scaleY(-1);
  }

  #filterImage {
    width: 17px;
    height: 17px;
  }

  #pagination {
    border-bottom-width: 1px solid #dee2e6 !important;
  }

  .tableHeight {
    height: 260px;
    overflow-y: scroll;
    display: block !important;
  }

  .ios_tableHeader {
    font-family: "Rubik" !important;
    background-color: #c2d8e5;
    color: rgb(66, 66, 66);
    font-weight: 700;
    font-size: 18px !important;
    color: #005d91;
    white-space: nowrap;
    border: none !important;
    // max-width: 420px;
  }

  .ios_tableHeader::before {
    content: "";
    position: absolute;
    background-color: #ffffff;
    width: 3px;
    height: 30px;
    right: 0px;
    top: 0px;
    margin: 14px 0px;
    border-radius: 50px;
  }

  .dataTable-table th {
    height: 50px;
  }

  .ios_tableRow {
    color: #6c757d;
    font-family: "Rubik" !important;
    // padding: 14px;
    // max-width: 420px;
    white-space: nowrap;
    overflow-x: auto;
  }

  tbody > tr:nth-child(even) > td {
    background-color: #ecf0f2;
  }

  tbody > tr:nth-child(odd) > td {
    background-color: #ffffff;
  }

  tbody > tr > td:not(:last-child):not(.first-child) {
    border-right: 1px solid;
    border-image: radial-gradient(#000000, #00000000) 0 1;
  }

  thead > tr > th:nth-child(1) {
    border-radius: "5px 0px 0px 0px";
  }

  tbody > tr:last-child > td {
    border: none;
  }

  .ios_tableRow::-webkit-scrollbar-thumb {
    // background: rgb(142 132 132 / 9%);
    background-color: #70707016;
    border-radius: 6px;
    border-left: 1px solid rgba(94, 94, 94, 0.308);
    border-right: 1px solid rgba(94, 94, 94, 0.308);
    height: 3px;

    &:hover {
      background-color: rgb(158, 165, 171);
    }
  }

  .ios_tableRow::-webkit-scrollbar {
    height: 5px !important;
  }

  .set-summary {
    white-space: nowrap;
    text-shadow: 0px 1px 1px #4d4d4d;
    color: #222;
    border-bottom: 1px solid #4d4d4d;
    cursor: pointer;
  }

  .scroll::-webkit-scrollbar-thumb {
    // background: rgb(142 132 132 / 9%);
    background-color: #70707016;
    border-radius: 6px;
    border-left: 1px solid rgba(94, 94, 94, 0.308);
    border-right: 1px solid rgba(94, 94, 94, 0.308);

    &:hover {
      background-color: rgb(158, 165, 171);
    }
  }

  // td::-webkit-scrollbar-track-piece:end {
  //   background: transparent;
  //   margin-bottom: 8px;
  // }
  .scroll::-webkit-scrollbar-track-piece {
    box-shadow: inset 0 0 3px rgb(128 128 128 / 37%);
    border-radius: 6px;
  }

  .scroll::-webkit-scrollbar {
    // display: block;
    position: absolute;
    width: 8px;
    height: 5px;
  }

  ::-webkit-scrollbar {
    // display: block;
    position: absolute;
    width: 12px !important;
    height: 9px;
  }

  .css-bsph2u-MuiTableCell-root {
    padding: 14px 10px !important;
  }

  #pagination {
    border-bottom-width: 1px solid #dee2e6 !important;
  }
  thead > tr > :last-child {
    border-radius: 0px 8px 0px 0px;
    position: static;
  }
  .MuiPagination-root {
    position: relative;
    box-shadow: none !important;
    display: flex !important;
    justify-content: end !important;
    padding: 10px !important;
  }

  .Mui-selected {
    background-color: #ffffff !important;
    color: black;
    border: 1px solid #005d91 !important;
    border-radius: 5px;
  }

  .pagination-bar {
    width: 100%;
    display: flex;
    justify-content: end;
  }
}
