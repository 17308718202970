.SummaryDetails-card-chartBox {
  background-color: #fff;
  padding: 1.5rem;
  -webkit-box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
  box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
  margin-bottom: 24px;
  border-radius: 0.25rem;
  .SummaryDetails-card-header-chartBox {
    background-color: #005d91 !important;
    padding: 1.5rem;
    color: #fff !important;
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }

  .SummaryDetails-card-body-chartBox {
    padding: 1rem;
    border: 2px solid #005d91;
    background: "#fff";
  }
}
