.dropdown-sidebar-container {
  display: flex !important;
  flex-direction: column !important;
  height: calc(100vh - 358px) !important;
  position: relative !important; // Add this line

  .dropdown-toggle-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    background: #005d91 !important;
    color: #ffffff !important;
    font-size: 15px !important;
    cursor: pointer !important;
    padding: 10px 15px !important;
    border-radius: 5px !important;
    transition: background-color 0.3s ease !important;

    &:hover {
      background-color: #004d7a !important;
    }

    span {
      margin-left: 10px !important;
    }

    .toggle-dropdown-btn-dashboard {
      color: white !important;
    }
  }

  .dropdown-sidebar {
    background-color: #e5eef4 !important;
    color: #6c757d !important;
    transition: max-height 0.3s ease !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
    overflow: hidden !important;
    max-height: 0 !important;
    position: absolute !important; // Add this line
    top: 100% !important; // Add this line

    &.expanded-dropdown {
      max-height: 500px !important;
      position: absolute !important; // Add this line
      top: 10% !important; // Add this line
      z-index: 100 !important; // Add this line
      width: 100% !important; // Add this line to ensure full width
    }

    .dropdown-tab-list {
      list-style: none !important;
      padding: 0 !important;
      margin: 0 !important;

      .dropdown-tab-item {
        display: flex !important;
        align-items: center !important;
        padding: 15px 15px !important;
        cursor: pointer !important;
        font-size: 12px !important;
        border-top: 1px solid #ddd !important;
        transition: background-color 0.3s ease !important;

        .MuiSvgIcon-root {
          font-size: 24px !important;
          margin-right: 7px !important;
        }

        .dropdown-tab-text {
          flex: 1 !important;
        }

        &:hover {
          background-color: #dce5ea !important;
        }

        &.active {
          background-color: #ffffff !important;
          color: #005d91 !important;
        }
      }
    }

    &:not(.collapsed-dropdown) .dropdown-tab-list {
      display: block !important;
    }
  }

  .dropdown-sidebar-table-container {
    flex: 1 !important;
    width: 100% !important;
    margin-top: 10px !important;
  }
}

@media (max-width: 768px) {
  .dropdown-sidebar-container {
    .dropdown-sidebar {
      width: 100% !important;
    }

    .dropdown-sidebar-table-container {
      width: 100% !important;
    }
  }
}
