.ticket-raise-container {
  padding: 1% 3%;

  .sidebar-form-container {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    min-height: calc(100vh - 370px);
    border-radius: 15px;
    display: flex;

    @media (min-width: 0px) and (max-width: 1024px) {
      height: 65vh;
      min-height: calc(100vh - 345px);
    }

    .ticket-form-sidebar {
      @media (min-width: 0px) and (max-width: 768px) {
        display: none;
      }

      width: 30%;
      padding: 2% 2%;

      .div-sidebar {
        background-color: #bfd6e3;
        border-radius: 15px;
        height: 100%;
        padding-top: 10%;

        .logo-ticket {
          width: 50%;
          margin: auto;
          img {
            width: 100%;
          }
        }

        .step-1 {
          padding: 15% 10% 0% 10%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .step-1-userLogo {
            display: flex;
            width: 18%;
            justify-content: center;
            background-color: azure;
            border-radius: 100%;

            img {
              width: 77%;
            }
          }
        }

        .step-2 {
          padding: 5% 10% 0 10%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .step-2-ticketLogo {
            display: flex;
            width: 18%;
            padding: 1%;
            justify-content: center;
            background-color: azure;
            border-radius: 100%;

            img {
              width: 100%;
            }
          }
        }
      }
    }

    .forms-container {
      width: 70%;

      @media (min-width: 0px) and (max-width: 768px) {
        width: 100%;
      }

      .user-detail-form {
        width: 100%;
        padding: 3% 5%;

        .input-row {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
      }

      .ticket-detail-form {
        width: 100%;
        padding: 1% 4%;

        .input-row {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }
}

.submit-history-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  flex-direction: column;
}
.error-text {
  color: red;
}
div > ul > .MuiButtonBase-root {
  display: flex !important;
  justify-content: flex-start !important;
  line-height: 1.5rem !important;
  padding: 6px 15px !important;
}
