#account-comp {
  // margin: 0px 16px;
  // padding: 0px 16px;

  #account-details {
    display: flex;
  }

  .account-card {
    background-color: #fff;
    // padding: 1.5rem;
    margin: 10px;
    -webkit-box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
    box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
    margin-bottom: 24px;
    border-radius: 0.25rem;
    // width: 33.33%;
    height: 63vh;
    border: 2px solid #005d91;
    @media (max-width: 1600px) {
      border: 1px solid #005d91;
      height: 73vh;
    }

    .account-card-header {
      background-color: #005d91 !important;
      padding: 0.5rem;
      color: #fff !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 46px;
      @media (max-width: 1600px) {
        font-size: 13px;
      }
    }

    .mb-2 {
      margin-bottom: 0.75rem;
      @media (max-width: 1600px) {
        margin-bottom: 0.3125rem;
      }
    }

    .p-10 {
      padding: 10px;
    }

    .set-card-icons {
      margin-right: 6px;
    }

    .available-countries {
      font-size: 12px;
      font-weight: 600;
      margin-right: 5px;
    }

    .verify-email {
      color: #005d91;
      font-size: 11px;
      font-weight: 900;
      margin-right: 3px;
    }

    .pending-email {
      color: #f7b84b;
      font-size: 11px;
      font-weight: 900;
      margin-right: 3px;
    }

    .account-card-title {
      margin-top: 10px;
      font-weight: 500;
      font-family: "Rubik" !important;
      color: rgb(52, 58, 64);
      font-size: 20px;
      @media (max-width: 1600px) {
        font-size: 14px;
      }
    }

    .account-card-body {
      padding: 1rem;

      .account-card-body-content {
        margin-top: 20px;
        @media (max-width: 1600px) {
          margin-top: 10px;
        }
      }

      .account-card-body-content-key {
        font-size: 15px;
        font-weight: 600;
        color: #005d91;
      }

      .account-card-body-content-value {
        margin-left: 10px;
      }
    }
  }

  .plan-button {
    width: 280px;
    float: right;
  }
}

.user-switch-container {
  display: flex !important;
  align-items: center !important;
  border: 1px solid #f4f4f5 !important;
  border-radius: 6px !important;
  padding: 5px 5px !important;
  background-color: #00000017 !important;
  color: black !important;
  gap: 3px !important;

  .user-type-btn {
    border: none !important;
    padding: 5px 20px !important;
    cursor: pointer !important;
    color: black !important;
    transition: all ease-in 0.2s !important;
    border-radius: 5px !important;

    .user-btn-text {
      text-transform: uppercase;
      font-size: 14px;
      color: #086294 !important;

      &.active-text {
        text-transform: uppercase;
        font-size: 14px;
        color: white !important;
      }
    }

    &:hover {
      background-color: #f0f0f0 !important;
    }

    &.active-btn {
      background-color: #086294 !important;
    }
  }
}
