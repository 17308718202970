.main-div {
  width: 100% !important;
  height: 550px;
  overflow-y: scroll !important;
  color: #303235;

  &::-webkit-scrollbar {
    width: 7px;
  }

  .list-selected {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .heading-selected-country {
      font-weight: bold;
      margin-top: 16px;
    }
    .container-selected-country {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .selected-country-tag {
        display: flex;
        align-items: center;
        background-color: #005d91;
        color: white;
        margin-left: 8px;
        padding: 5px;
        border-radius: 5px;
        font-size: 12px;
        letter-spacing: 1px;
        .cancel-icon-span {
          cursor: pointer;
          display: flex;
          align-items: center;
          .cancel-icon-for-selected-country {
            font-size: 15px;
            margin-right: 8px;
          }
        }
      }
    }
  }

  .searched-country {
    display: flex;
    flex-wrap: wrap;

    .country-span {
      width: 250px;
      display: flex;
      align-items: center;
    }
    .country-name {
      font-size: 13px;
      font-weight: 400;
    }
  }
  .country-list-container {
    margin-bottom: 16px;

    .region-heading {
      font-weight: bold;
      margin-top: 16px;
      margin-bottom: 8px;
    }

    .country-list {
      width: 100%;
      flex-wrap: wrap;
      display: flex;
      color: #303235;

      .country-span {
        display: flex;
        margin-right: 40px;
        height: 23px;
        align-items: center;
        margin: 10px 0 10px 0;

        @media (min-width: 0px) and (max-width: 324px) {
          width: 100%;
        }

        @media (min-width: 324px) and (max-width: 425px) {
          width: 50%;
        }

        @media (min-width: 426px) and (max-width: 1024px) {
          width: 33%;
        }

        @media (min-width: 1025px) {
          width: 20%;
        }

        .country-name {
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }
}
