// Ensure menu items are displayed vertically
.highcharts-menu li {
  display: block;
  width: 100%;
  text-align: left;
  padding: 8px 15px;
  cursor: pointer;
}
// Hover state for menu items
.highcharts-menu li:hover {
  background-color: #f0f0f0;
  color: #003366;
}

// Customize the context menu button (optional)
.highcharts-contextbutton .highcharts-button-symbol {
  fill: #333;
}

// Optional: Style the entire chart container to prevent overflow issues
.highcharts-container {
  overflow: hidden;
}

// Optional: Style for tooltip for a better appearance
.highcharts-tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
}

// Optional: Style for legend items when clicked
.highcharts-legend-item {
  cursor: pointer;
}

// Optional: Style for legend items when active (visible)
.highcharts-legend-item-hidden {
  opacity: 0.5;
}
