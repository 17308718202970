body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > div.MuiDialog-root .MuiDialog-scrollPaper .MuiPaper-root {
  max-width: 1250px !important;
  min-width: 1250px !important;
}
body > #webpack-dev-server-client-overlay {
  display: none !important;
}
body > #webpack-dev-server-client-overlay-div {
  display: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
