.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-family: "Arial", sans-serif;

  th,
  td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  button {
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    border: none;
    background-color: #f0f0f0;
    font-size: 14px;

    &.active {
      font-weight: bold;
      background-color: #005d91;
      color: white;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #ccc;
    }

    &:hover:not(:disabled) {
      background-color: #ddd;
    }
  }
}
