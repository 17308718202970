#workSpace-main-container {
  .summary-btn-container-dashboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    margin: 10px 0px;
    .summary-btn-container-dashboard :nth-child(1) {
      margin-left: 0px !important;
    }
    .summary-dashboard {
      @media screen and (max-width: 1070px) {
        width: 100%;
      }
    }
  }

  .workspace-tab {
    padding-top: 10px;

    .tab-btn {
      // gap: 1%;
      // display: flex;
      // width: 100%;

      .workspace-tabBtn {
        width: 100%;
        background: #dce5ea;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #dce5ea;
        border-radius: 5px;
        cursor: pointer;

        .tabBtn-content {
          display: flex;
          align-items: center;
          color: #005d91;
          width: 100%;
          justify-content: center;
          gap: 3%;
          font-size: 1.2rem;
        }
      }

      #selectedTabBtn {
        height: 60px !important;
        border-radius: 5px 5px 0px 0px !important;
        padding-bottom: 10px;
      }
    }

    // .tab-content {
    //   padding: 1% 1% 0.5px 1%;
    //   background-color: #dce5ea;
    //   margin-bottom: 1.2%;
    // }
  }

  .workSpace-filter {
    margin-left: -20px !important;
    margin-right: -20px !important;
    // height: 10px;
    .workSpaceFilter-div {
      background: rgb(236, 240, 242);
      padding-left: 0px;
      padding-top: 0px;
      margin-top: 16px;
      border-radius: 0px 8px 0px 0px;

      #workSpaceFilter {
        position: sticky;
        top: 0px;
        z-index: 1000;
      }
    }

    .filter-label-off-workspace {
      width: 4%;
      padding: 0px 0px 0px 10px;
      position: sticky;
      top: 0px;
      left: 1px;
      z-index: 10100;

      #set-filters-showing-arrow-workspace {
        cursor: pointer;
        padding: 0px 4px;
        height: 42px;
        top: 27px;
        left: 15px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        background: #005d91;
        color: white;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        width: 68px;
      }
    }
  }
}
