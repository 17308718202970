#toast {
  position: absolute;
  top: 50px;
  right: 10px;
  width: 20%;
//   padding: 20px;
  z-index: 10000;
  border-radius: 10px;
  background-color: white;
  #cmp-name {
    font-size: 14px !important;
    font-weight: 600;
  }
  #text-small {
    font-size: 14px !important;
  }
  #toast-header{
    background: red;
    padding: 13px 9px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    color: white;
  }
  .toast-body{
   padding: 10px !important;   
  }
//   .toast-header{
//     padding: 10px;
//   }
}
