.all-apps-container {
  // position: relative;

  .all-apps-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 16px;
    border: 1px solid #ced4da;
    background-color: #ffffff;
    color: #495057;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    height: 35px;

    &:hover {
      background-color: #e9ecef;
    }

    &:active {
      background-color: #dee2e6;
    }
  }

  .popup-list {
    position: absolute;
    // top: 100%;
    // left: 0;
    top: 85%;
    right: 5px;
    margin-top: 12px;
    width: 600px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 10;
    overflow: hidden;

    // @media (max-width: 752px) {
    //   left: -293px;
    // }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      margin: 0px 2px !important;

      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        padding: 12px;
        border-bottom: 1px solid #f1f1f1;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #f9f9f9;
        }

        .item-icon {
          color: #005d91ba;
          font-size: 30px;
          padding: 3px;
          border-radius: 5px;
          background-color: #c2d8e57a;
        }

        .item-content {
          width: 100% !important;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h4 {
            margin: 0 0 4px 0;
            font-size: 15px;
            font-weight: 600;
            color: #333333eb;
            display: flex;
            align-items: center;
            gap: 8px;

            .coming-soon-badge {
              display: inline-block;
              background: linear-gradient(90deg, #d4e9f9, #f0f8fc);
              color: #005d91;
              font-size: 12px;
              font-weight: bold;
              padding: 2px 8px;
              border-radius: 10px;
              border: 1px solid #b3d4ea;
              position: relative;
              overflow: hidden;

              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: -100%;
                width: 200%;
                height: 100%;
                background: linear-gradient(
                  90deg,
                  rgba(255, 255, 255, 0) 0%,
                  rgba(255, 255, 255, 0.5) 50%,
                  rgba(255, 255, 255, 0) 100%
                );
                animation: subtle-shine 2s infinite;
                border-radius: 10px;
              }
            }

            @keyframes subtle-shine {
              0% {
                transform: translateX(-100%);
              }
              100% {
                transform: translateX(100%);
              }
            }

            .active-panel-badge {
              display: inline-block;
              color: #005d91;
              font-size: 12px;
              font-weight: bold !important;
              padding: 2px 8px;
              border-radius: 10px;
              position: relative;
              overflow: hidden;
              background: #c2d8e578;
            }
          }

          p {
            margin: 0;
            font-size: 14px;
            color: #666666;
          }

          .action-container {
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }

      li:last-child {
        border-bottom: none;
        margin-bottom: 2px;
      }
    }
  }
}
